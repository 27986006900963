import {getInitializedApp,} from '@/assets/js/src/util/appRef'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {useAuthStore,} from '@/assets/js/src/pinia/auth'
import {Logger,} from '@/assets/js/src/util/logger'

let globalThis = (1, eval)('this')

export const notifyError = function (err, info) {
    let notifyEventHandler = function (event) {
        let {pinia,} = getInitializedApp()
        event.addMetadata('custom', {
            userType: useUserStore(pinia).loggedIn ? 'Member' : 'Visitor',
            ...info,
        })
    }

    if (globalThis.bgsng) {
        globalThis.bgsng.notify(err, notifyEventHandler)

        return
    }

    if (import.meta.env.DEV) {
        Logger.error({
            msg: err.message || err,
            info,
        })

        return
    }

    (async () => {
        try {
            const bugsnag = await import(/* webpackChunkName: "Bugsnag" */'@bugsnag/js')
            const Bugsnag = bugsnag.default

            let plugins = []
            if (!import.meta.env.SSR) {
                const bugsnagVue = await import(/* webpackChunkName: "Bugsnag" */'@bugsnag/plugin-vue')
                const BugsnagPluginVue = bugsnagVue.default
                plugins = [ new BugsnagPluginVue(), ]
            }

            let {pinia: firstStore,} = getInitializedApp()

            Bugsnag.start({
                 
                apiKey: import.meta.env.SSR ? __BS_BUGSNAG_NODE_API_KEY__ : useAuthStore(firstStore).bugsnagAuth,
                 
                appVersion: __BS_VERSION__, // __BS_VERSION__ is const injection via webpack.Defineplugin
                 
                releaseStage: __BS_RELEASE_STAGE__, // __BS_RELEASE_STAGE__ is const injection via webpack.Defineplugin
                plugins,
            })
            globalThis.bgsng = Bugsnag
            globalThis.bgsng.notify(err, notifyEventHandler)
        } catch (initError) {
            Logger.error({
                msg: err.message || err,
                info,
                initError,
            })
        }
    })()
}

export function errorHandler (err, vm, info) {
    if (typeof vm === 'undefined') {
        notifyError(err, info)

        return
    }

    let handler = null
    let current = vm
    while (current) {
        if (current.$options.errorHandler) {
            handler = current.$options.errorHandler
            break
        }
        current = current.$parent
    }

    info = {
        info,
        tag: vm.$.type?.name,
        lastRoutes: import.meta.env.SSR ?
            vm.$pinia.state.value.routeMeta.url :
            vm.$pinia.state.value.appUi.lastRoutes,
    }

    if (handler) {
        handler.call(current, err, vm, info)

        return
    }

    notifyError(err, info)
}

export class ServiceWorkerRegError extends Error {
    constructor (message) {
        super(message)
        this.name = 'ServiceWorkerRegError'
    }
}

export class ApiResponseError extends Error {
    constructor (message) {
        super(message)
        this.name = 'ApiResponseError'
    }
}

export class BsError extends Error {
    constructor (exception, payload) {
        super('BsError with extra info')
        this.name = 'BsError'
        this.payload = payload
        this.exception = exception
    }
}

export class BsNotFoundError extends Error {
    constructor (payload) {
        super('Resource not found')
        this.name = 'BsNotFoundError'
        this.payload = payload
        this.exception = this
    }
}
