<template>
    <header
        class="bs-header"
        :class="{'bs-header--banner-visible':banner}"
    >
        <div
            class="bs-header-wrapper hidden-print-only main-header"
            :class="[mainHeaderClasses,homeHeaderClasses]"
        ></div>
        <button
            v-if="messagesLoaded"
            class="d-sr-only skip-link hidden-print-only"
            @click.stop="focusContent($event)"
        >{{ t('async.common.button.skip_link') }}
        </button>
        <keep-alive>
            <bs-autocomplete
                v-if="!overlay || pageNotFound"
                id="bs-home__autocomplete-wrapper"
                class="bs-header__autocomplete-wrapper hidden-print-only"
                :class="[
                    {
                        'bs-header__autocomplete-wrapper--banner-visible': banner,
                        'bs-header__autocomplete-wrapper--active':isAutocompleteActive || (isOptionWrapperActive && isSearch),
                        'bs-header__autocomplete-wrapper--active-child':isOptionWrapperActive,
                        'bs-home__autocomplete-wrapper':home,
                        'bs-home__autocomplete-wrapper--home':home && !homeTop,
                        'bs-home__autocomplete-wrapper--home-top':homeTop,
                        'bs-home__autocomplete-wrapper--hide':homeHide,
                        'bs-home__autocomplete-wrapper--transition':homeTransition,
                        'bs-page-not-found__autocomplete-wrapper':pageNotFound
                    },
                    !home ? userMenuClass : '',
                    mainHeaderClasses,
                ]"
            />
        </keep-alive>
        <template
            v-if="!overlay"
        >
            <transition-group
                :key="`mobile-option-wrapper-${$vuetify.display.smAndDown}`"
                name="switchOption"
                mode="out-in"
                class="bs-header__option-wrapper hidden-print-only"
                :class="[
                    {
                        'bs-header__option-wrapper--banner-visible': banner,
                        'bs-header__option-wrapper--active':isOptionWrapperActive,
                        'bs-header__option-wrapper--autocomplete':getComponentVisibility('AUTOCOMPLETE'),
                        'bs-header__option-wrapper--autocomplete--is-search':getComponentVisibility('AUTOCOMPLETE') && isSearch,
                    },
                    !home ? userMenuClass : '',
                    mainHeaderClasses,
                ]"
                tag="div"
            >
                <v-btn
                    v-if="!isSearch && !home"
                    id="TEXT_FORMAT_BTN"
                    key="1"
                    :aria-pressed="getComponentVisibility('TEXT_FORMAT') ? 'true':'false'"
                    :aria-label="messagesLoaded ? t('async.autocomplete.text_format') : ''"
                    :title="messagesLoaded ? t('async.autocomplete.text_format') : ''"
                    :disabled="getLoadingStatus('TextFormats')"
                    icon
                    class="bs-header__option-wrapper__btn bs-header__btn--change-text"
                    @click="setComponentVisible('TEXT_FORMAT')"
                >
                    <v-icon size="large">
                        bs:$vuetify.icons.textFormat
                    </v-icon>
                </v-btn>

                <v-btn
                    v-else-if="isSearch"
                    id="SEARCH_OPTIONS_BTN"
                    key="2"
                    :aria-pressed="getComponentVisibility('SEARCH_OPTIONS') ? 'true':'false'"
                    :aria-label="messagesLoaded ? t('async.autocomplete.search_options') : ''"
                    :title="messagesLoaded ? t('async.autocomplete.search_options') : ''"
                    :disabled="getLoadingStatus('SearchOptions')"
                    :class="[{
                        'bs-header__option-wrapper__btn--notification': isSearchOptionDefaults() && !isOptionWrapperActive,
                        'bs-header__option-wrapper__btn--home':!homeTop && home,
                    }]"
                    icon
                    class="bs-header__option-wrapper__btn bs-header__btn--change-text"
                    @click="setComponentVisible('SEARCH_OPTIONS')"
                >
                    <v-icon size="large">
                        bs:$vuetify.icons.mdiFilter
                    </v-icon>
                </v-btn>
            </transition-group>
        </template>
        <bs-user
            v-if="(!overlay || (overlay && ($route.meta || {}).landingPage)) && messagesLoaded"
            id="help-tour-text-5"
            class="bs-header__user hidden-print-only"
            :class="[{
                         'bs-header__user--banner-visible':banner,
                         'bs-header__user--active':getComponentVisibility('USER_POPOVER'),
                     },
                     mainHeaderClasses,]"
            :home="home"
        />
        <v-no-ssr>
            <v-btn
                v-if="overlay && countRouteChange > 0 && !($route.meta || {}).landingPage"
                class="bs-header__btn bs-header__btn--close hidden-print-only"
                :class="{'bs-header__btn--close--banner-visible':banner}"
                aria-pressed="false"
                :aria-label="messagesLoaded ? t('async.common.button.close') : ''"
                :title="messagesLoaded ? t('async.common.button.close') : ''"
                icon
                @click="overlayHandler"
            >
                <v-icon size="large">
                    bs:$vuetify.icons.mdiClose
                </v-icon>
            </v-btn>
        </v-no-ssr>
        <transition name="fade">
            <div
                v-if="isComponentVisible && !overlay"
                class="bs-header__darken"
                tabindex="0"
            ></div>
        </transition>
    </header>
</template>

<script>
import Autocomplete from '@/assets/js/src/modules/autocomplete/_components/Autocomplete.vue'
import {mapState, mapActions, mapWritableState, getActivePinia, storeToRefs,} from 'pinia'
import BsUser from '@/assets/js/src/modules/user/_components/BsUser.vue'
import {initHeadroom,} from '@/assets/js/src/util/uiTools'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {useAutocompleteStore,} from '@/assets/js/src/modules/autocomplete/_pinia/autocomplete'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {useSearchStore,} from '@/assets/js/src/modules/search/_pinia/search'
import {useOverlayRouteAction,} from '@/assets/js/src/util/composables/useOverlayRouteAction'
import {useTranslation,} from "@/assets/js/src/util/composables/useTranslation"
import {useIsTop,} from "@/assets/js/src/util/composables/useIsTop.js"
import {useRoute,} from "vue-router"

export default {
    name: 'BsHeader',
    components: {
        BsUser,
        'bs-autocomplete': Autocomplete,
    },
    props: {
        notification: {
            type: Boolean,
            default: false,
        },
        messagesLoaded: {
            type: Boolean,
            default: false,
        },
    },
    setup () {
        let activePinia = getActivePinia()
        let route = useRoute()

        const {
            banner,
            overlay,
            overlayModal,
            home,
            homeHide,
            homeTop,
            homeTransition,
            backgroundFade,
            countRouteChange,
            pageNotFound,
            blockHeadroom,
            mobileView,
            mainHeaderClasses,
        } = storeToRefs(useAppUiStore(activePinia))
        
        const {
            isComponentVisible,
            getComponentVisibility,
            getLoadingStatus,
            isSearch,
        } = storeToRefs(useAutocompleteStore(activePinia))

        // UserStore
        const { loggedIn, nickname, user, } = storeToRefs(useUserStore(activePinia))

        // SearchStore
        const { isChanged, } = storeToRefs(useSearchStore(activePinia))

        !import.meta.env.SSR && route.meta.landingPage && useIsTop()
        
        return {
            banner,
            overlay,
            overlayModal,
            home,
            homeHide,
            homeTop,
            homeTransition,
            backgroundFade,
            countRouteChange,
            pageNotFound,
            blockHeadroom,
            mobileView,
            mainHeaderClasses,
            isComponentVisible,
            getComponentVisibility,
            getLoadingStatus,
            isSearch,
            loggedIn,
            nickname,
            user,
            isChanged,
            ...useOverlayRouteAction(),
            ...useTranslation(),
        }
    },
    data: () => {
        return {
            userMenuClass: '',
            widthClass: '',
            homeHeaderClasses: {},
        }
    },
    computed: {
        isAutocompleteActive: function () {
            return this.getComponentVisibility('BOOK_SELECT') || this.getComponentVisibility('AUTOCOMPLETE') || this.getComponentVisibility('BIBLE_SELECT')
        },
        isOptionWrapperActive: function () {
            return this.getComponentVisibility('TEXT_FORMAT') || this.getComponentVisibility('SEARCH_OPTIONS')
        },
        ...mapState(useAppUiStore, [
            'banner',
            'overlay',
            'overlayModal',
            'home',
            'homeHide',
            'homeTop',
            'homeTransition',
            'backgroundFade',
            'countRouteChange',
            'pageNotFound',
            'blockHeadroom',
            'mobileView',
        ]),
        ...mapWritableState(useAppUiStore, [
            'mainHeaderClasses',
        ]),
        ...mapState(useAutocompleteStore, [
            'isComponentVisible',
            'getComponentVisibility',
            'getLoadingStatus',
        ]),
        ...mapWritableState(useAutocompleteStore, [
            'isSearch',
        ]),
        ...mapState(useUserStore, [
            'loggedIn',
            'nickname',
            'user',
        ]),
        ...mapState(useSearchStore,[
            'isChanged',
        ]),
        isFixedHeader () {
            return this.user.options.fixHeader && !this.mobileView
        },
    },
    watch: {
        nickname: {
            immediate: true,
            handler: function (newVal) {
                this.messagesLoaded && this.calculateUserNameWidth(newVal)
            },
        },
        messagesLoaded: {
            immediate: true,
            handler: function (newVal, oldVal) {
                if (newVal !== oldVal && newVal) {
                    this.calculateUserNameWidth(this.nickname)
                }
            },
        },
        home: {
            immediate: !import.meta.env.SSR,
            handler: function (newVal, oldVal) {
                if (newVal) {
                    this.userMenuClass = ''
                    this.homeHeaderClasses['bs-home__header-wrapper'] = true
                    this.homeHeaderClasses['bs-home__header-wrapper--top'] = true
                } else if (newVal !== oldVal) {
                    this.messagesLoaded && this.calculateUserNameWidth(this.nickname)
                    this.homeHeaderClasses['bs-home__header-wrapper'] = false
                    this.homeHeaderClasses['bs-home__header-wrapper--top'] = false
                }
            },
        },
        getComponentVisibility: {
            handler: function (newVal, oldVal) {
                if ([ 'Home', 'HomeML', ].includes(this.$route.name)) {
                    return
                }
                let newBool = Object.values(newVal).some((visibility) => visibility === true)
                let oldBool = Object.values(oldVal ?? {}).some((visibility) => visibility === true)
                if (newBool !== oldBool && newBool) {
                    if (!import.meta.env.SSR) {
                        let fixElem = document.querySelector('main.v-main')
                        this.offset = window.pageYOffset
                        fixElem.style.position = 'fixed'
                        fixElem.style.width = '100%'
                        fixElem.style.top = (-1 * this.offset) + 'px'
                        fixElem.style.minHeight = '100%'
                    }
                } else if (newBool !== oldBool && !newBool) {
                    if (!import.meta.env.SSR) {
                        let fixElem = document.querySelector('main.v-main')
                        fixElem.style.position = 'relative'
                        fixElem.style.top = 'auto'
                        fixElem.style.minHeight = 'auto'
                        window.scrollTo(0, this.offset)
                    }
                }
            },
        },
        isFixedHeader: {
            handler (newVal) {
                // Korregiere die Header-Klassen, falls nötig
                if(this.user.options.fixHeader && newVal) {
                    this.mainHeaderClasses = {
                        'main-header--pinned': true,
                        'main-header--top': true,
                        'main-header--unpinned': false,
                    }
                }
            },
            immediate: !import.meta.env.SSR,
        },
    },
    mounted () {
        let check = true
        if (this.isSearch) {
            this.isSearch = false
            check = false
        }

        this.$nextTick(() => {
            if (!check) {
                this.isSearch = true
            }

            initHeadroom(this.$pinia)
        })
    },
    methods: {
        ...mapActions(useAppUiStore, [ 'setOverlayModal', ]),
        ...mapActions(useAutocompleteStore, [ 'setComponentVisible', ]),
        calculateUserNameWidth (nickname) {
            if (this.loggedIn && nickname.trim().length > 0) {
                if (nickname.trim().length > 20) {
                    this.userMenuClass = ' max-width'
                } else if (nickname.trim().length > 7) {
                    this.userMenuClass = ' width-' + nickname.trim().length
                } else {
                    this.userMenuClass = ''
                }
            } else {
                let text = this.t('async.common.greeting.default')

                if (text.trim().length > 20) {
                    this.userMenuClass = ' max-width'
                } else if (text.trim().length > 7) {
                    this.userMenuClass = ' width-' + text.trim().length
                } else {
                    this.userMenuClass = ''
                }
            }
        },
        overlayHandler: function () {
            // Verhalten beim Schließen der Overlays
            if (this.overlay && this.overlayModal) {
                this.setOverlayModal({show:false,}).catch(() => {
                })
            } else if (this.$route.meta.uiType === 'Overlay') {
                this.overlayRouteAction(true)
            }
        },
        isSearchOptionDefaults: function () {
            try {
                return this.isChanged && this.isSearch
            } catch (e) {
                return false
            }
        },
        focusContent () {
            let main = document.querySelector('#main-content')
            if (!main) {
                return
            }
            // Find all focusable children
            let focusableElementsString = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]'
            let focusableElements = main.querySelectorAll(focusableElementsString)
            // Convert NodeList to Array
            focusableElements = Array.prototype.slice.call(focusableElements)
            let firstTabStop = focusableElements[0]
            firstTabStop.focus()
        },
    },
}
</script>

<style lang="scss">
    .bs-app {
        .bs-header {

            /** Option Wrapper Transition **/

            .switchOption-enter-active, .switchOption-leave-active {
                box-shadow: none;
                transition: all .2s cubic-bezier(0, 1.5, .5, 1);
            }

            .switchOption-enter-from, .switchOption-leave-to {
                transform: translateX(50px);
            }

            .switchOption-leave-from, .switchOption-enter-to {
                transform: translateX(0);
            }

            /** Dark Overlay Transition **/

            .fade-enter-active, .fade-leave-active {
                transition: opacity .5s;
            }

            .fade-enter-from, .fade-leave-to {
                opacity: 0;
            }

            &-wrapper {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 9990;
                width: 100%;
                height: map-deep-get($bs-xl, header, height);
                background: map-deep-get($bs-color, bs);
                opacity: 1;

                @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                    height: map-deep-get($bs-md, header, height);
                }

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    height: map-deep-get($bs-xs, header, height);
                }

                @at-root .bs-app--top .bs-header-wrapper {
                    opacity: 0;
                }

                &.main-header {
                    position: fixed;
                    top: 0;
                    right: 0;
                    left: 0;
                    z-index: 9990;
                    transition: transform .5s ease-in-out, background .2s ease-in-out;

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        top: 0;
                    }

                    &--unpinned {
                        top: -#{map-deep-get($bs-xl, header, height)};
                        transform: translateY(-#{map-deep-get($bs-xl, header, height)});
                        transition: .5s;

                        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                            top: -#{map-deep-get($bs-xs, header, height)};
                            transform: translateY(-#{map-deep-get($bs-xs, header, height)});
                            transition: .5s;
                        }
                    }

                    &--pinned {
                        top: 0;
                        transition: .5s;

                        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                            top: 0;
                            transition: .5s;
                        }
                    }
                }
            }

            &__darken {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 9998;
                width: 100vw;
                height: 100vh;
                background: rgb(0 0 0 / 50%);
            }

            &__autocomplete-wrapper {
                position: fixed;
                top: math.div(map-deep-get($bs-xl, header, height), 2) - math.div(map-deep-get($bs-xl, autocomplete, height), 2);
                left: 300px;
                z-index: 9991;
                width: calc(100vw - 540px); // 100vw - 300px - 140px - 50px - 50px

                @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                    top: math.div(map-deep-get($bs-md, header, height), 2) - math.div(map-deep-get($bs-md, autocomplete, height), 2);
                    width: calc(100vw - 528px); // calc(100vw - 300px - 138px - 45px - 45px);
                }

                @media (width <= 960px) {
                    @include cache((
                        width: calc(100% - 438px),
                    )); // calc(100% - 300px - 138px)
                }

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    top: math.div(map-deep-get($bs-xs, header, height), 2) - math.div(map-deep-get($bs-md, autocomplete, height), 2);
                    left: 45px;
                    width: calc(100% - 115px); // calc(100% - 60px - 55px);
                }

                @for $num from 1 through 13 {
                    &.width-#{$num + 7} {
                        width: calc(100vw - #{540px + $num * 8}); // calc(100vw - 300px - (140px + num * 8) - 50px - 50px)

                        @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                            width: calc(100vw - #{538px + $num * 8}); // calc(100vw - 300px - (138px + num * 8) - 50px - 50px)
                        }

                        @media (width <= 960px) {
                            @include cache((
                                width: calc(100% - 438px), // calc(100% - 300px - 138px)
                            ));
                        }

                        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                            @include cache((
                                width: calc(100% - 115px), // calc(100% - 60px - 55px)
                            ));
                        }
                    }
                }

                &.max-width {
                    width: calc(100vw - 655px); // 100vw - 300px - 255px - 50px - 50px

                    @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                        width: calc(100vw - 644px); //calc(100vw - 300px - 244px - 50px - 50px)
                    }

                    @media (width <= 960px) {
                        @include cache((
                            width: calc(100% - 438px), // calc(100% - 300px - 138px)
                        ));
                    }

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        @include cache((
                            width: calc(100% - 115px),
                        )); // calc(100% - 60px - 55px)
                    }
                }

                &--banner-visible {
                    top: math.div(map-deep-get($bs-xl, header, height), 2) + map-deep-get($bs-xl, banner, height) - math.div(map-deep-get($bs-xl, autocomplete, height), 2);

                    @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                        top: math.div(map-deep-get($bs-md, header, height), 2) + map-deep-get($bs-xl, banner, height) - math.div(map-deep-get($bs-md, autocomplete, height), 2);
                    }

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        top: math.div(map-deep-get($bs-xs, header, height), 2) + map-deep-get($bs-xs, banner, height) - math.div(map-deep-get($bs-md, autocomplete, height), 2);
                    }
                }

                &--active {
                    @include cache((
                        z-index: 9999,
                    ));
                }

                &--active-child {
                    @include cache((
                        z-index: 9999,
                    ));

                    background: map-deep-get($bs-color, white);

                    @include dark {
                        background: map-deep-get($bs-color, dark, white);
                    }

                    border-radius: map-deep-get($bs-xl, autocomplete, inputRadius);

                    .autocomplete-input-wrapper {
                        background: rgb(0 0 0 / 50%);

                        input {
                            background: rgb(255 255 255 / 0%) !important;
                        }
                    }

                    .text-format-wrapper {
                        @include cache((
                            z-index: 9999,
                        ));
                    }
                }

                &.main-header {
                    &--unpinned {
                        top: -#{map-deep-get($bs-xl, header, height)};
                        transform: translateY(-#{map-deep-get($bs-xl, header, height)});
                        transition: .5s;
                    }

                    &--pinned:not(.bs-home__autocomplete-wrapper--home, .bs-home__autocomplete-wrapper--home-top) {
                        transition: .5s;
                    }
                }
            }

            &__btn {
                &--close {
                    position: fixed;
                    top: #{math.div(map-deep-get($bs-xl, header,height), 2) - math.div(map-deep-get($bs-xl, header, btn, size) + 10, 2)};
                    right: 40px;
                    left: auto;
                    z-index: 9990;
                    margin: 0 !important;
                    background: transparent !important;
                    box-shadow: none !important;

                    @include cache((
                        width: 10px + map-deep-get($bs-xl, header,btn,size),
                        height: 10px + map-deep-get($bs-xl, header,btn,size),
                    ));

                    @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                        position: fixed;
                        top: math.div(map-deep-get($bs-md, header, height), 2) - math.div(map-deep-get($bs-md, header, btn, size) + 10, 2);

                        @include cache((
                            width: 10px + map-deep-get($bs-md, header,btn,size),
                            height: 10px + map-deep-get($bs-md, header,btn,size),
                        ));
                    }

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        position: fixed;
                        top: math.div(map-deep-get($bs-xs, header, height), 2) - math.div(map-deep-get($bs-md, header, btn, size) + 10, 2);
                        right: 6px;
                    }

                    &--banner-visible {
                        top: #{math.div(map-deep-get($bs-xl, header,height), 2) + map-deep-get($bs-xl, banner, height) - math.div(map-deep-get($bs-xl, header, btn, size) + 10, 2)};

                        @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                            top: math.div(map-deep-get($bs-md, header, height), 2) + map-deep-get($bs-xl, banner, height) - math.div(map-deep-get($bs-md, header, btn, size) + 10, 2);
                        }

                        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                            top: math.div(map-deep-get($bs-xs, header, height), 2) + map-deep-get($bs-xs, banner, height) - math.div(map-deep-get($bs-md, header, btn, size) + 10, 2);
                        }
                    }

                    .v-icon {
                        width: map-deep-get($bs-xl, header, btn, size);
                        height: map-deep-get($bs-xl, header, btn, size);
                        color: map-deep-get($bs-color, white);

                        @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                            width: map-deep-get($bs-md, header, btn, size);
                            height: map-deep-get($bs-md, header, btn, size);
                        }
                    }

                    &:hover, &:focus {
                        background: map-deep-get($bs-color, greyLight) !important;

                        .v-icon {
                            color: map-deep-get($bs-color, bs);
                        }
                    }
                }
            }

            &__option-wrapper {
                position: fixed;
                top: math.div(map-deep-get($bs-xl, header, height), 2) - math.div(map-deep-get($bs-xl, header, btn, size) + 10, 2);
                right: auto;
                left: 0;
                z-index: 9990;
                width: calc(100vw - 190px); // 100vw - 140px - 50px
                max-width: 1020px;
                height: map-deep-get($bs-xl, header, btn, size) + 10;
                overflow: hidden;
                pointer-events: none;

                @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                    top: math.div(map-deep-get($bs-md, header, height), 2) - math.div(map-deep-get($bs-md, header, btn, size) + 10, 2);
                    width: calc(100vw - 187px); // calc(100vw - 142px - 45px)
                    max-width: 1015px;
                    height: map-deep-get($bs-md, header, btn, size) + 10;
                }

                @media (width <= 960px) {
                    @include cache((
                        width: calc(100% - 92px),
                    ));
                }

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    top: math.div(map-deep-get($bs-xs, header, height), 2) - math.div(map-deep-get($bs-md, header, btn, size) + 10, 2);
                    right: 6px;
                    left: auto;
                }

                &--active {
                    @include cache((
                        z-index: 9999 !important,
                    ));
                }

                @for $num from 1 through 13 {
                    &.width-#{$num + 7} {
                        width: calc(100vw - #{190px + $num * 8});

                        @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                            width: calc(100vw - #{192px + $num * 8}); // calc(100vw - %s - 50px)" % (142px + num * 8)
                        }

                        @media (width <= 960px) {
                            @include cache((
                                width: calc(100% - 92px),
                            ));
                        }
                    }
                }

                &.max-width {
                    width: calc(100vw - 299px); // 100vw - 244px - 55px

                    @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                        width: calc(100vw - 299px); // 100vw - 244px - 55px
                    }

                    @media (width <= 960px) {
                        @include cache((
                            width: calc(100% - 92px),
                        ));
                    }
                }

                &--autocomplete {
                    @include cache((
                        z-index: 9999,
                    ));

                    &--is-search {
                        z-index: 9999 !important;
                    }
                }

                &--banner-visible {
                    top: math.div(map-deep-get($bs-xl, header, height), 2) + map-deep-get($bs-xl, banner, height) - math.div(map-deep-get($bs-xl, header, btn, size) + 10, 2);

                    @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                        top: math.div(map-deep-get($bs-md, header, height), 2) + map-deep-get($bs-xl, banner, height) - math.div(map-deep-get($bs-md, header, btn, size) + 10, 2);
                    }

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        top: math.div(map-deep-get($bs-xs, header, height), 2) + map-deep-get($bs-xs, banner, height) - math.div(map-deep-get($bs-md, header, btn, size) + 10, 2);
                    }
                }

                &__btn {
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: auto;
                    margin: 0 !important;
                    background: transparent !important;
                    box-shadow: none !important;
                    pointer-events: all;

                    @include cache((
                        width: 10px + map-deep-get($bs-xl, header,btn,size),
                        height: 10px + map-deep-get($bs-xl, header,btn,size),
                    ));

                    @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                        @include cache((
                            width: 10px + map-deep-get($bs-md, header,btn,size),
                            height: 10px + map-deep-get($bs-md, header,btn,size),
                        ));
                    }

                    .v-icon {
                        color: map-deep-get($bs-color, white);

                        @include cache((
                            width: map-deep-get($bs-xl, header, btn, size),
                            height: map-deep-get($bs-xl, header, btn, size),
                        ));

                        @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                            @include cache((
                                width: map-deep-get($bs-md, header, btn, size),
                                height: map-deep-get($bs-md, header, btn, size),
                            ));
                        }
                    }

                    &:hover:not(.bs-header__option-wrapper__btn--home), &:focus {
                        background: map-deep-get($bs-color, greyLight) !important;

                        .v-icon {
                            color: map-deep-get($bs-color, bs);
                        }
                    }

                    &--notification {
                        &::after {
                            position: absolute;
                            top: 5px;
                            right: 5px;
                            display: block;
                            width: 8px;
                            height: 8px;
                            background: map-deep-get($bs-color, bsHighlight);
                            border-radius: 8px;
                            content: " ";
                        }
                    }

                    &--highlight {
                        @include cache((
                            z-index: 9999,
                        ));
                    }
                }

                &.main-header {
                    &--unpinned {
                        top: -#{map-deep-get($bs-xl, header, height)};
                        transform: translateY(-#{map-deep-get($bs-xl, header, height)});
                        transition: .5s;
                    }

                    &--pinned {
                        transition: .5s;
                    }
                }
            }

            &__user {
                position: fixed;
                top: (math.div(map-deep-get($bs-xl, header, height), 2) - 23px);
                right: 40px;
                left: auto;
                z-index: 9991;

                @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                    position: fixed;
                    top: (math.div(map-deep-get($bs-md, header, height), 2) - 20px);
                    right: 40px;
                    z-index: 9990;
                }

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    display: none !important;
                }

                &--banner-visible {
                    top: (math.div(map-deep-get($bs-xl, header, height), 2) + map-deep-get($bs-xl, banner, height) - 23px);

                    @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                        top: (math.div(map-deep-get($bs-md, header, height), 2) + map-deep-get($bs-xl, banner, height) - 20);
                    }
                }

                &.bs-header__user--active {
                    @include cache((
                        z-index: 9999,
                    ));

                    @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                        z-index: 9999;
                    }
                }

                &__btn {
                    margin: 0 !important;
                    background: transparent !important;
                    box-shadow: none !important;

                    @include cache((
                        width: 10px + map-deep-get($bs-xl, header,btn,size),
                        height: 10px + map-deep-get($bs-xl, header,btn,size),
                    ));

                    @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                        @include cache((
                            width: 10px + map-deep-get($bs-md, header,btn,size),
                            height: 10px + map-deep-get($bs-md, header,btn,size),
                        ));
                    }

                    .v-icon {
                        color: #fff;
                    }

                    .avatar {
                        background: transparent;
                    }

                    &:hover, &:focus, &.active {
                        color: transparent;
                        background: map-deep-get($bs-color, greyLight) !important;

                        .v-icon {
                            color: map-deep-get($bs-color, bs);
                        }
                    }

                    &--login {
                        .v-icon {
                            @include cache((
                                width: 10px + map-deep-get($bs-xl, header,btn,size),
                                height: 10px + map-deep-get($bs-xl, header,btn,size),
                            ));

                            @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                                @include cache((
                                    width: 10px + map-deep-get($bs-md, header,btn,size),
                                    height: 10px + map-deep-get($bs-md, header,btn,size),
                                ));
                            }
                        }
                    }
                }

                &.main-header {
                    &--unpinned {
                        top: -#{map-deep-get($bs-xl, header, height)};
                        transform: translateY(-#{map-deep-get($bs-xl, header, height)});
                        transition: .5s;
                    }

                    &--pinned {
                        transition: .5s;
                    }
                }
            }

            .skip-link:focus {
                top: 0;
                left: 0;
                z-index: 10000;
                width: auto;
                height: auto;
                margin: 0 !important;
                padding: 4px 16px !important;
                color: map-deep-get($bs-color, bsHighlight);
                background: map-deep-get($bs-color, bs);
                clip: auto;
            }

            &--banner-visible {
                .bs-header-wrapper.main-header {
                    top: map-deep-get($bs-xl, banner, height);

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        top: map-deep-get($bs-xs, banner, height);
                    }

                    &--unpinned {
                        top: map-deep-get($bs-xl, banner, height) - map-deep-get($bs-xl, header, height);
                        transform: translateY(map-deep-get($bs-xl, banner, height) - map-deep-get($bs-xl, header, height));

                        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                            top: map-deep-get($bs-xs, banner, height) - map-deep-get($bs-xs, header, height);
                            transform: translateY(-1 * map-deep-get($bs-xs, banner, height) - map-deep-get($bs-xs, header, height));
                        }
                    }

                    &--pinned, &--top {
                        top: map-deep-get($bs-xl, banner, height);

                        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                            top: map-deep-get($bs-xs, banner, height);
                        }
                    }
                }
            }
        }
    }
</style>
