<template>
    <div
        class="autocomplete-wrapper"
        aria-live="polite"
    >
        <div class="autocomplete-input-wrapper">
            <button
                id="help-tour-text-2"
                ref="BOOK_SELECT_BTN"
                :class="{active:getComponentVisibility('BOOK_SELECT')}"
                :aria-pressed="getComponentVisibility('BOOK_SELECT')? 'true':'false'"
                :aria-label="messagesLoaded ? t('async.autocomplete.book_select') : ''"
                :title="messagesLoaded ? t('async.autocomplete.book_select') : ''"
                class="autocomplete-btn showBooknamesBtn"
                role="button"
                tabindex="0"
                :disabled="getLoadingStatus('BookSelect')"
                @click="setComponentVisible('BOOK_SELECT')"
            >
                <v-icon>bs:$vuetify.icons.mdiLibraryBooks</v-icon>
            </button>
            <form
                method="GET"
                action="/opensearch"
                target="_self"
                class="autocomplete-input__form"
                @submit.prevent
            >
                <input
                    id="help-tour-text-3"
                    ref="AUTOCOMPLETE_BTN"
                    name="search"
                    :value="input"
                    :placeholder="messagesLoaded ? t('async.autocomplete.placeholder') : ''"
                    :aria-label="messagesLoaded ? t('async.autocomplete.placeholder') : ''"
                    aria-controls=""
                    :title="messagesLoaded ? t('async.autocomplete.placeholder') : ''"
                    :aria-haspopup="false"
                    type="text"
                    class="autocomplete-input"
                    :class="{'autocomplete-firefox':isFFandAndroid,'autocomplete-firefox__selected':isFFandAndroid && sBibles.length > 1}"
                    tabindex="0"
                    autocomplete="off"
                    autocorrect="off"
                    autocapitalize="off"
                    autofocus="autofocus"
                    spellcheck="false"
                    role="search"
                    @input="syncInput"
                    @keyup="handleKeyUp"
                    @paste="onPaste"
                    @focus="handleIosFocus(true)"
                    @blur="handleIosFocus(false)"
                >
                <input
                    type="hidden"
                    name="abbreviation"
                    :value="(sBibles || []).join('.')"
                />
            </form>
            <div
                v-show="(input || '').trim() !== ''"
                class="autocomplete-input-enter"
                role="button"
                tabindex="0"
                :title="messagesLoaded ? (!isSearch ? t('async.autocomplete.enter.text'):t('async.autocomplete.enter.search')) : ''"
                :aria-label="messagesLoaded ? (!isSearch ? t('async.autocomplete.enter.text'):t('async.autocomplete.enter.search')) : ''"
                @click="handleInputAction($event,-1,false,false,lastInput === input)"
                @keydown="handleInputAction($event, -1,false,false,lastInput === input)"
            >
                <v-icon v-show="lastInput !== input">
                    bs:$vuetify.icons.mdiKeyboardReturn
                </v-icon>
                <v-icon
                    v-show="lastInput === input"
                    color="secondary"
                    class="autocomplete-input-enter--close"
                >
                    bs:$vuetify.icons.mdiClose
                </v-icon>
            </div>
            <button
                id="help-tour-text-4"
                ref="BIBLE_SELECT_BTN"
                :class="{active:getComponentVisibility('BIBLE_SELECT')}"
                class="autocomplete-btn showBiblesBtn"
                :aria-pressed="getComponentVisibility('BIBLE_SELECT') ? 'true':'false'"
                :aria-label="messagesLoaded ? t('async.autocomplete.bible_select') : ''"
                :title="messagesLoaded ? t('async.autocomplete.bible_select') : ''"
                role="button"
                tabindex="0"
                :disabled="getLoadingStatus('BibleSelect')"
                @click="setComponentVisible('BIBLE_SELECT')"
            >
                {{ selectedBiblesText }}
                <v-icon
                    color="secondary"
                >
                    bs:$vuetify.icons.mdiMenuDown
                </v-icon>
            </button>
        </div>
        <transition-group
            mode="out-in"
            name="dropdown"
            tag="div"
        >
            <book-select
                v-if="getComponentVisibility('BOOK_SELECT')"
                id="BOOK_SELECT"
                ref="BOOK_SELECT"
                :key="1"
                :messages-loaded="messagesLoaded"
                class="autocomplete-elem-wrapper"
                :class="{'autocomplete-elem-wrapper--banner-visible':banner}"
            ></book-select>
            <div
                v-if="getComponentVisibility('AUTOCOMPLETE')"
                id="AUTOCOMPLETE"
                ref="AUTOCOMPLETE"
                :key="2"
                class="autocomplete-elem-wrapper autocomplete-select-wrapper"
                :class="{'autocomplete-elem-wrapper--banner-visible':banner}"
            >
                <transition-group
                    name="list"
                    tag="ul"
                >
                    <li
                        v-for="(bookname,key) in filteredBooknames"
                        :key="'li_'+key"
                    >
                        <button
                            :class="{'selected':(aktPos === key),'search':(bookname.type === 'search'),'to-be-searched':bookname.toBeSearched}"
                            :aria-pressed="false"
                            :aria-label="bookname.type === 'search' ? (messagesLoaded ? t('async.autocomplete.search') +' ' : '')+bookname.input:bookname.default"
                            :title="bookname.type === 'search' ? (messagesLoaded ? t('async.autocomplete.search') +' ' : '')+bookname.input:bookname.default"
                            class="autocomplete-select-item text-truncate"
                            tabindex="-1"
                            role="button"
                            @click="handleDropDownClick($event, bookname,key)"
                        >
                            <span v-if="bookname.type === 'search'"><v-icon>bs:$vuetify.icons.mdiMagnify</v-icon></span> {{ bookname.type === 'search' ? (messagesLoaded ? t('async.autocomplete.search_for',{key:bookname.input}) : bookname.input):bookname.input }}
                        </button>
                    </li>
                </transition-group>
            </div>
            <bible-select
                v-if="getComponentVisibility('BIBLE_SELECT')"
                id="BIBLE_SELECT"
                ref="BIBLE_SELECT"
                :key="3"
                :messages-loaded="messagesLoaded"
                class="autocomplete-elem-wrapper"
                :class="{'autocomplete-elem-wrapper--banner-visible':banner}"
            ></bible-select>
            <div
                v-if="getComponentVisibility('TEXT_FORMAT')"
                id="TEXT_FORMAT"
                ref="TEXT_FORMAT"
                :key="4"
                :style="textFormatLeft"
                class="autocomplete-elem-wrapper text-format-wrapper"
                :class="{'autocomplete-elem-wrapper--banner-visible':banner}"
            >
                <v-icon
                    :style="textFormatAnchor"
                    class="arrow"
                >
                    bs:$vuetify.icons.mdiMenuUp
                </v-icon>
                <text-formats v-if="getComponentVisibility('TEXT_FORMAT')" />
            </div>
            <div
                v-if="getComponentVisibility('SEARCH_OPTIONS')"
                id="SEARCH_OPTIONS"
                ref="SEARCH_OPTIONS"
                :key="5"
                :style="textFormatLeft"
                class="autocomplete-elem-wrapper text-format-wrapper"
                :class="{'autocomplete-elem-wrapper--banner-visible':banner}"
            >
                <v-icon
                    :style="textFormatAnchor"
                    class="arrow"
                >
                    bs:$vuetify.icons.mdiMenuUp
                </v-icon>
                <search-options v-if="getComponentVisibility('SEARCH_OPTIONS')" />
            </div>
        </transition-group>
    </div>
</template>

<script>
import styleXl from '@/assets/js/src/style/json/variables'
import styleXs from '@/assets/js/src/style/json/variables-xs'
import {defineAsyncComponent,defineComponent,} from 'vue'
import {DIR_LTR,} from '@/assets/js/src/modules/lang/_pinia/lang'
import {debounce,} from '@/assets/js/src/util/uiTools'
import {clearInput,} from '@/assets/js/src/modules/autocomplete/_pinia/autocomplete'
import {useActivityStore,} from '@/assets/js/src/modules/activity/_pinia/activity'
import {getActivePinia, mapActions, storeToRefs,} from 'pinia'
import {useBibleStore,} from '@/assets/js/src/modules/bible/_pinia/bible'
import {useAutocompleteStore,} from '@/assets/js/src/modules/autocomplete/_pinia/autocomplete'
import {useRouteMetaStore,} from '@/assets/js/src/pinia/routeMeta'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {usePrefetchData,} from '@/assets/js/src/util/composables/usePrefetchData'
import {useBooknamesStore,} from '@/assets/js/src/modules/bible/_pinia/booknames'
import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {useTextStore,} from '@/assets/js/src/modules/text/_pinia/text'
import {useLoading,} from '@/assets/js/src/util/composables/useLoading'
import {useTranslation,} from '@/assets/js/src/util/composables/useTranslation'

const idMap = {
    'BOOK_SELECT_BTN': 'help-tour-text-2',
    'AUTOCOMPLETE_BTN': 'help-tour-text-3',
    'BIBLE_SELECT_BTN': 'help-tour-text-4',
}

export default {
    name: 'Autocomplete',
    components: {
        BookSelect: defineAsyncComponent({
            loader: () => import('@/assets/js/src/modules/autocomplete/_components/BookSelect.vue'),
            loadingComponent: defineComponent(useLoading({type: 'BookSelect',})),
            delay: 0,
        }),
        BibleSelect: defineAsyncComponent({
            loader: () => import('@/assets/js/src/modules/autocomplete/_components/BibleSelect.vue'),
            loadingComponent: defineComponent(useLoading({type: 'BibleSelect',})),
            delay: 0,
        }),
        TextFormats: defineAsyncComponent({
            loader: () => import('@/assets/js/src/layout/TextFormats.vue'),
            loadingComponent: defineComponent(useLoading({type: 'TextFormats',})),
            delay: 0,
        }),
        SearchOptions: defineAsyncComponent({
            loader: () => import('@/assets/js/src/layout/SearchOptions.vue'),
            loadingComponent: defineComponent(useLoading({type: 'SearchOptions',})),
            delay: 0,
        }),
    },
    setup () {
        let activePinia = getActivePinia()

        // BibleStore
        const bibleStore = useBibleStore(activePinia)
        const { selectedBibles: sBibles, } = storeToRefs(bibleStore)

        // AutocompleteStore
        const autocompleteStore = useAutocompleteStore(activePinia)
        const {
            filteredBooknames,
            getComponentVisibility,
            getVisibleComponent,
            getVisibleComponentCached,
            getLoadingStatus,
            isComponentVisible,
            reload,
            reloadSearch,
            isSearch,
            autocompleteInput: input,
        } = storeToRefs(autocompleteStore)

        // RouteMetaStore
        const routeMetaStore = useRouteMetaStore(activePinia)
        const {
            type,
            autocomplete,
            book,
            selectedVerses,
            reload: routeMetaReload,
            url: routeMetaUrl,
            isSet: routeMetaIsSet,
            canonical: routeMetaCanonical,
            chapterCanonical: routeMetaChapterCanonical,
            bibles: routeMetaBibles,
            search: routeMetaSearch,
        } = storeToRefs(routeMetaStore)

        // AppUiStore
        const appUiStore = useAppUiStore(activePinia)
        const {
            home,
            banner,
            homeLoad,
            backgroundFade,
            homeTransition,
        } = storeToRefs(appUiStore)

        // LangStore
        const langStore = useLangStore(activePinia)
        const { locale, localeDirection, } = storeToRefs(langStore)

        // UserStore
        const userStore = useUserStore(activePinia)
        const { loggedIn, } = storeToRefs(userStore)

        // TextStore
        const textStore = useTextStore(activePinia)
        const {
            resetScrollPosition,
            chapterCanonical,
            abbreviations,
        } = storeToRefs(textStore)

        // BooknamesStore
        const booknamesStore = useBooknamesStore(activePinia)
        const { booknames, } = storeToRefs(booknamesStore)

        let {messagesLoaded,} = usePrefetchData({
            actions: [
                {
                    item: booknames,
                    action: booknamesStore.loadBooknames,
                    payload: function () {
                        return langStore.locale
                    },
                },
            ],
            loadAsyncMessages: [ 'autocomplete', ],
        })

        return {
            // BibleStore
            sBibles,

            // AutocompleteStore
            filteredBooknames,
            getComponentVisibility,
            getVisibleComponent,
            getVisibleComponentCached,
            getLoadingStatus,
            isComponentVisible,
            reload,
            reloadSearch,
            isSearch,
            input,

            // RouteMetaStore
            type,
            autocomplete,
            book,
            selectedVerses,
            routeMetaReload,
            routeMetaUrl,
            routeMetaIsSet,
            routeMetaCanonical,
            routeMetaChapterCanonical,
            routeMetaBibles,
            routeMetaSearch,

            // AppUiStore
            home,
            banner,
            homeLoad,
            backgroundFade,
            homeTransition,

            // LangStore
            locale,
            localeDirection,

            // UserStore
            loggedIn,

            // TextStore
            resetScrollPosition,
            chapterCanonical,
            abbreviations,

            // BooknamesStore
            booknames,

            // usePrefetchData
            messagesLoaded,
            ...useTranslation(),
        }
    },
    data: () => ({
        aktPos: -1,
        dropDownBlocked: false,
        textFormatWidth: '345px',
        textFormatLeft: 'left:100%',
        textFormatAnchor: 'left:0px',
        bodyWidth: 0,
        lastInput: '',
        offset: 0,
        styleXl,
    }),
    computed: {
        selectedBiblesText: function () {
            try {
                if (this.sBibles.length - 1 > 0) {
                    return (this.sBibles[0] + '+' + (this.sBibles.length - 1))
                } else {
                    return this.sBibles[0]
                }
            } catch (e) {
                return ''
            }
        },
        isFFandAndroid: function () {
            return globalThis.isFF && globalThis.isAndroid
        },
    },
    watch: {
        input: function (value) {
            if (this.dropDownBlocked === false) {
                if (typeof this.getVisibleComponentCached === 'undefined' && this.reload === false && value.trim() !== '') {
                    !this.getComponentVisibility('AUTOCOMPLETE') && this.setComponentVisible('AUTOCOMPLETE')
                }
            } else {
                this.dropDownBlocked = false
            }
        },
        // Sprachänderung überwachen
        locale: {
            immediate: !import.meta.env.SSR,
            async handler (newVal, oldVal) {
                if (newVal !== oldVal) {
                    let booknamesStore = useBooknamesStore(getActivePinia())
                    await booknamesStore.loadBooknames(newVal)
                }
            },
        },
        // Reload überwachen
        reload: function (newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                if (this.input.trim() === '' && !this.home) {
                    this.input = this.autocomplete
                }

                if (this.input.trim() !== '') {
                    if (this.home) {
                        this.homeTransition = true
                    }

                    let url = this.getRouteMetaUrl()

                    if (this.reloadSearch === true) {
                        if(this.filteredBooknames.length > 1 && this.input.trim().match(/\D(\s?\d?\d?\d?\s?(?:\.|\,|\:)?\s?\d?\d?\d?\s?-?\s?\d?\d?\d)$/mg)) {
                            let pattern = ''
                            for (let i = 0; i < this.filteredBooknames.length - 1; i++) {
                                pattern += `${i === 0 ? '' : '|'}${this.filteredBooknames[i].default}[\.\s]?`
                            }
                            let regexp = new RegExp(pattern, 'i')
                            let clearedInput = clearInput(this.input.trim())
                            if(regexp.test(clearedInput.input)) {
                                this.$bsa && this.$bsa.event({
                                    eventCategory: 'autocomplete',
                                    eventAction: `Dropdown search (click) - redirected to text`,
                                    eventLabel: this.input,
                                })
                                this.loadRouteMeta({url: url, type: 'text', reload: true,})
                            } else {
                                this.loadRouteMeta({url: url, type: 'search', reload: true,})
                            }
                        } else {
                            this.loadRouteMeta({url: url, type: 'search', reload: true,})
                        }
                    } else {
                        let fallback = this.filteredBooknames[0] ? this.filteredBooknames[0].input : ''
                        this.loadRouteMeta({url: url, type: 'text', fallback: fallback, reload: true,})
                    }
                }
                this.hideAllComponents()
                this.reload = false
                this.reloadSearch = false
            } else {
                let selectedVerses = globalThis.clone(this.selectedVerses)
                let verses = []
                selectedVerses.forEach((elem) => {
                    for (let i = elem[0]; i <= elem[elem.length - 1]; i++) {
                        verses.push(i)
                    }
                })

                this.setSelectedVerses({selectedVerses: [], selected: false,})
                this.setSelectedVerses({selectedVerses: verses, selected: true,})
                this.resetScrollPosition = true
            }
        },
        // URL Änderung überwachen
        routeMetaUrl: {
            immediate: true,
            handler (url, oldUrl) {
                // Verhindern, dass eine falsch erkannte Route eine gültige Routen überschreibt
                if(typeof oldUrl === 'undefined' && ![ 'SearchView','TextView', ].includes(this.$route.meta.uiType)) {
                    return
                }

                if (url.trim() !== '' && this.type !== 'notFound') {
                    this.updateAutocompleteInput(this.autocomplete, typeof oldUrl === 'undefined')
                    this.setSelectedBook(this.book)
                }

                // Falls die url eigentlich gleich ist jedoch wg url encoding abweicht
                if (
                    ((decodeURIComponent(this.$route.path) === this.routeMetaCanonical)
                        || (this.type === 'text' &&
                            this.chapterCanonical &&
                            this.chapterCanonical === this.routeMetaChapterCanonical &&
                            JSON.stringify(this.abbreviations) === JSON.stringify(this.routeMetaBibles)))
                        && !this.routeMetaReload
                        && typeof oldUrl !== 'undefined'
                ) {
                    return
                }

                if (url.trim() !== '') {
                    if (this.type === 'search') {
                        this.$router.push({path: url,}).catch(() => {
                        })
                        this.isSearch = true
                    } else if (this.type === 'notFound') {
                        return
                    } else {
                        this.$router.push({path: url,}).catch(() => {
                        })
                    }
                }
            },
        },
        // URL Änderung überwachen
        routeMetaIsSet: {
            immediate: true,
            handler (set, oldSet) {
                if (set !== oldSet && set) {
                    this.updateAutocompleteInput(this.autocomplete)
                    this.setSelectedBook(this.book)
                    this.setIsSet(false)
                }
            },
        },
        homeLoad: function (newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                let url = globalThis.clone(this.routeMetaUrl)

                if (url.trim() !== '') {
                    if (this.type === 'search') {
                        this.$router.push({path: url,}).catch(() => {
                        })
                    } else if (this.type === 'notFound') {
                        return
                    } else {
                        this.$router.push({path: url,}).catch(() => {
                        })
                    }

                    this.updateAutocompleteInput(this.autocomplete)
                    this.setSelectedBook(this.book)
                }
                let appUiStore = useAppUiStore(getActivePinia())
                appUiStore.homeLoad = false
            }
        },
        filteredBooknames: {
            immediate: false,
            handler (newVal, oldVal) {
                if (newVal !== oldVal) {
                    let reg = new RegExp(/"(.*)"/gmi)
                    let isSearchRoute = this.$route.meta.uiType === 'SearchView' && this.input.trim() === this.routeMetaSearch

                    if (newVal.length === 1 || reg.test(this.input.trim()) || isSearchRoute) {
                        this.isSearch = true
                    } else {
                        this.isSearch = false
                    }
                }
            },
        },
        // Auf Startseite Autocomplete leeren
        home: {
            immediate: true,
            handler (newVal, oldVal) {
                if (newVal !== oldVal && newVal) {
                    this.input = ''
                    this.isSearch = false
                }
            },
        },
    },
    created () {
        if (!import.meta.env.SSR) {
            document.addEventListener('keydown', this.handleGeneralKeyUp)

            // Activity tracken --> erste primäre Aktivität
            if (this.loggedIn) {
                if (this.type === 'search' || this.type === 'text') {
                    let activityStore = useActivityStore(getActivePinia())
                    activityStore.addActivity({
                        name: globalThis.clone(this.autocomplete),
                        route: globalThis.clone(this.routeMetaUrl),
                        type: globalThis.clone(this.type),
                        abbreviations: globalThis.clone(this.sBibles),
                        parent: 0,
                    })
                }
            }
        }

        // Größe des Text-Formats PopUp
        this.$watch(this.getVisibleComponent, (value) => {
            if (value === 'TEXT_FORMAT' || value === 'SEARCH_OPTIONS') {
                setTimeout(() => {
                    let bodyWidth = document.querySelector('body').clientWidth
                    let btn = this.getOffset(document.querySelector('#' + value + '_BTN'))
                    let btnWidth = document.querySelector('#' + value + '_BTN').clientWidth
                    let aWrapper = this.getOffset(document.querySelector('.autocomplete-wrapper'))
                    let aWrapperWidth = document.querySelector('.autocomplete-wrapper').clientWidth
                    let isLtr = this.localeDirection === DIR_LTR

                    if(this.home) {
                        aWrapperWidth = aWrapperWidth / 2
                    }

                    let versatz = 0
                    let anchorVersatz = 0
                    if (isLtr) {
                        versatz = bodyWidth - ((aWrapper.left + aWrapperWidth) + parseInt(this.styleXl.header.textFormat.maxWidth.replace('px', '')) + 16)
                    } else {
                        versatz = bodyWidth - (((bodyWidth - (aWrapper.left + aWrapperWidth)) + aWrapperWidth) + parseInt(this.styleXl.header.textFormat.maxWidth.replace('px', '')) + 16)
                    }

                    if (isLtr) {
                        anchorVersatz = (btn.left - (aWrapper.left + aWrapperWidth)) + (btnWidth / 2)
                    } else {
                        anchorVersatz = ((bodyWidth - (btn.left + btnWidth)) - ((bodyWidth - (aWrapper.left + aWrapperWidth)) + aWrapperWidth)) + (btnWidth / 2)
                    }

                    if (bodyWidth > parseInt(styleXs.breakpointValue)) {
                        if (versatz < 0) {
                            this.textFormatLeft = (isLtr ? 'left:' : 'right:') + 'calc(100% - ' + (-1 * versatz) + 'px)'
                            this.textFormatAnchor = (isLtr ? 'left:' : 'right:') + (-1 * versatz + anchorVersatz) + 'px'

                        } else {
                            this.textFormatLeft = (isLtr ? 'left:' : 'right:') + '100%'
                            this.textFormatAnchor = (isLtr ? 'left:' : 'right:') + anchorVersatz + 'px'
                        }
                    } else {
                        this.textFormatLeft = isLtr ? 'left:0' : 'right:0'
                        this.textFormatAnchor = isLtr ? 'left:0' : 'right:0'
                    }
                }, 100)
            }
        })

        if (!import.meta.env.SSR) {
            document.addEventListener('click', this.handleDocumentClick)
            document.addEventListener('keydown', this.handleKeyPress)
        }
    },
    mounted () {
        if (this.type === 'search') {
            this.isSearch = true
        } else {
            this.isSearch = false
        }

        if (!globalThis.isIOS) {
            // Fokus auf AutocompleteInput
            this.$refs['AUTOCOMPLETE_BTN'].focus()
            let length = this.input.length
            this.$refs['AUTOCOMPLETE_BTN'].setSelectionRange && this.$refs['AUTOCOMPLETE_BTN'].setSelectionRange(length, length)
        }
    },
    unmounted () {
        if (!import.meta.env.SSR) {
            document.removeEventListener('click', this.handleDocumentClick)
            document.removeEventListener('keydown', this.handleKeyPress)
            document.removeEventListener('keydown', this.handleGeneralKeyUp)

            if (this.$refs['AUTOCOMPLETE_BTN']) {
                this.$refs['AUTOCOMPLETE_BTN'].removeEventListener('focus', this.handleIosFocus(true))
                this.$refs['AUTOCOMPLETE_BTN'].removeEventListener('blur', this.handleIosFocus(false))
            }
        }
    },
    methods: {
        ...mapActions(useTextStore,[
            'setSelectedVerses',
        ]),
        handleIosFocus (focus) {
            if (window.MAIN_HEADROOM && focus) {
                this.backgroundFade = false

                window.MAIN_HEADROOM.top()
                window.MAIN_HEADROOM.pin()
            } else {
                this.backgroundFade = true
            }

            if (globalThis.isIOS && !globalThis.isMac) {
                let fixElem = document.querySelector('.v-application__wrap')

                if (focus) {
                    this.offset = window.pageYOffset
                    fixElem.style.width = '100%'
                    fixElem.style.height = 'calc(var(--vh) * 100)'
                    fixElem.style.overflow = 'hidden'
                    fixElem.scrollTo && fixElem.scrollTo(0, this.offset)
                } else {
                    fixElem.style.width = null
                    fixElem.style.height = null
                    fixElem.style.overflow = null
                    window.scrollTo(0, this.offset)
                }
            }
        },
        getRouteMetaUrl () {
            let bibles = this.sBibles
            let abbr = bibles.join('.')

            if (bibles.length === 0) {
                abbr = 'STANDARD'
            }

            return abbr + '/' + this.input.replace('/', ' ')
        },
        handleKeyPress: debounce( function (event) {
            if (this.isComponentVisible === false && event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA' && !event.target.classList.contains('ck-content')) {
                if (event.key === '/') {
                    event.preventDefault()
                    this.$refs['AUTOCOMPLETE_BTN'].focus()
                    let length = this.input.length
                    this.$refs['AUTOCOMPLETE_BTN'].setSelectionRange(0, length)
                    this.$bsa && this.$bsa.event({
                        eventCategory: 'shortcuts',
                        eventAction: '/ (Suchfeld)',
                    })
                }
            }
        },100),
        handleGeneralKeyUp: debounce(function (event) {
            let ctrl = event.ctrlKey
            if (globalThis.isMac) {
                ctrl = event.metaKey
            }

            /** ? **/
            if (event.key === '?' && event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA' && !event.target.classList.contains('ck-content')) {
                event.preventDefault()
                this.$bsa && this.$bsa.event({
                    eventCategory: 'shortcuts',
                    eventAction: '? (Hilfe)',
                })
                this.$router.push({path: '/help',})
                /** Shift + B **/
            } else if (!ctrl && event.key === 'B' && event.shiftKey && event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA' && !event.target.classList.contains('ck-content')) {
                this.hideAllComponents()
                this.$refs['AUTOCOMPLETE_BTN'].blur()
                this.setComponentVisible('BOOK_SELECT')
                event.preventDefault()
                this.$bsa && this.$bsa.event({
                    eventCategory: 'shortcuts',
                    eventAction: 'shift + b (Buchauswahl)',
                })
                /**  B **/
            } else if (!ctrl && event.key === 'b' && event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA' && !event.target.classList.contains('ck-content')) {
                this.hideAllComponents()
                this.$refs['AUTOCOMPLETE_BTN'].blur()
                this.setComponentVisible('BIBLE_SELECT')
                event.preventDefault()
                this.$bsa && this.$bsa.event({
                    eventCategory: 'shortcuts',
                    eventAction: 'b (Bibelauswahl)',
                })
            } else if ([ 'Escape', 'Esc', ].includes(event.key)) {
                this.$bsa && this.$bsa.event({
                    eventCategory: 'shortcuts',
                    eventAction: 'esc (Abbrechen)',
                })
                if (this.$refs['AUTOCOMPLETE_BTN'] === document.activeElement && this.getVisibleComponentCached === 'AUTOCOMPLETE') {
                    if (this.aktPos !== -1) {
                        this.aktPos = -1
                        this.input = this.filteredBooknames[this.filteredBooknames.length - 1].input
                    } else {
                        this.hideAllComponents()
                        this.$refs['AUTOCOMPLETE_BTN'].focus()
                    }

                } else if (this.$refs['AUTOCOMPLETE_BTN'] === document.activeElement) {
                    if (this.input.trim() === '') {
                        this.$refs['AUTOCOMPLETE_BTN'].blur()
                    }
                    this.updateAutocompleteInput('')
                } else {
                    this.$refs['AUTOCOMPLETE_BTN'].focus()
                }
            }

            if (document.activeElement === this.$refs['AUTOCOMPLETE_BTN'] && event.key === 'B' && ctrl) {
                event.preventDefault()
                this.hideAllComponents()
                this.$refs['AUTOCOMPLETE_BTN'].blur()
                this.setComponentVisible('BOOK_SELECT')
                this.$bsa && this.$bsa.event({
                    eventCategory: 'shortcuts',
                    eventAction: 'shift + b (Buchauswahl)',
                })

                /** Control + B **/
            } else if (document.activeElement === this.$refs['AUTOCOMPLETE_BTN'] && event.key === 'b' && ctrl) {
                this.hideAllComponents()
                this.$refs['AUTOCOMPLETE_BTN'].blur()
                this.setComponentVisible('BIBLE_SELECT')
                event.preventDefault()
                this.$bsa && this.$bsa.event({
                    eventCategory: 'shortcuts',
                    eventAction: 'b (Bibelauswahl)',
                })
            }
        },100),
        changeScrollTop (item, direction = '') {
            if (item + 1 >= 1) {
                let elem = this.$el.querySelector('ul li:nth-child(' + (item + 1) + ')')
                elem.focus()
                let btnHeight = elem.offsetHeight
                let offsetBtn = elem.offsetTop

                if (direction === 'bottom') {
                    let scrollTop = this.$el.querySelector('ul').scrollTop
                    let parentHeight = this.$el.querySelector('ul').offsetHeight

                    if (offsetBtn + btnHeight > scrollTop && (offsetBtn + btnHeight) - scrollTop > parentHeight) {
                        this.$el.querySelector('ul').scrollTop = offsetBtn + btnHeight - parentHeight
                    } else if (offsetBtn <= scrollTop) {
                        this.$el.querySelector('ul').scrollTop = 0
                    }
                } else if (direction === 'top') {
                    let scrollTop = this.$el.querySelector('ul').scrollTop
                    let scrollHeight = this.$el.querySelector('ul').scrollHeight

                    if (offsetBtn <= scrollTop && offsetBtn - btnHeight > btnHeight) {
                        this.$el.querySelector('ul').scrollTop = offsetBtn
                    } else if (offsetBtn - btnHeight <= btnHeight) {
                        this.$el.querySelector('ul').scrollTop = 0
                    } else if (scrollTop <= 0) {
                        this.$el.querySelector('ul').scrollTop = scrollHeight
                    }
                }
            }
        },
        handleKeyUp: debounce( function (event) {
            let booknames = this.filteredBooknames
            let countBooknames = this.filteredBooknames.length
            let component = this.getVisibleComponentCached
            this.dropDownBlocked = false

            if(!component && this.getComponentVisibility('AUTOCOMPLETE')) {
                component = 'AUTOCOMPLETE'
            }

            if (event.key === 'Enter' || event.keyCode === 13) {
                event.preventDefault()
            }

            if (this.input.trim() !== '' && component === 'AUTOCOMPLETE') {
                /** ArrowDown **/
                if ([ 'ArrowDown', 'Down', ].includes(event.key)) {
                    if (this.aktPos < countBooknames - 1) {
                        this.aktPos++
                        this.input = booknames[this.aktPos].input
                    } else {
                        this.aktPos = -1
                    }
                    if (countBooknames - 1 !== this.aktPos) {
                        this.changeScrollTop(this.aktPos, 'bottom')
                    }
                    /** ArrowUp **/
                } else if ([ 'ArrowUp', 'Up', ].includes(event.key)) {
                    if (this.aktPos === 0) {
                        this.aktPos = -1
                        this.input = booknames[countBooknames - 1].input
                    } else if (this.aktPos === -1) {
                        this.aktPos = countBooknames - 1
                        this.input = booknames[this.aktPos].input
                    } else {
                        this.aktPos--
                        this.input = booknames[this.aktPos].input
                    }
                    this.changeScrollTop(this.aktPos, 'top')
                    /** ENTER **/
                } else if (event.key === 'Enter' || event.keyCode === 13) { // keyCode als workaround für ios 9 (z.B. iPodTouch)
                    this.handleInputAction(event)
                } else {
                    // Vorschläge generieren
                    this.aktPos = -1
                    this.handleAutocompleteInput(this.input)
                }

                if (this.aktPos !== -1) {
                    this.resetToBeSearched()
                } else {
                    this.calculateToBeSearched()
                }
            } else if (event.key === 'Enter') {
                this.handleInputAction(event,-1, true)
            } else if (typeof component === 'undefined' && [ 'ArrowDown', 'Down', ].includes(event.key) && booknames.length > 0) {
                this.setComponentVisible('AUTOCOMPLETE')
            }
        },100),
        isDisabled: function (abbr) {
            if (this.sBibles.length <= 1) {
                return this.sBibles.indexOf(abbr) !== -1
            } else {
                return false
            }
        },
        handleDocumentClick (ev) {
            let component = this.getVisibleComponentCached

            if(!component) {
                return
            }

            // VueComponent oder nicht
            if (this.$refs[component]?.$el) {
                let btn = document.getElementById(idMap[component + '_BTN'])
                if (ev.target !== btn &&
                        btn && !btn.contains(ev.target) &&
                        ev.target !== this.$refs[component].$el &&
                        !this.$refs[component].$el.contains(ev.target)
                ) {
                    this.hideAllComponents()
                }

                return
            }

            let activator = document.querySelector('#' + component + '_BTN')
            let box = document.querySelector('#' + component)

            if (!activator) {
                activator = document.getElementById(idMap[component + '_BTN'])
            }
            if (box === null) {
                box = this.$refs[component]
            }

            if (activator &&
                ev.target !== activator &&
                !activator.contains(ev.target) &&
                ev.target !== box && box &&
                !box.contains(ev.target)) {
                this.hideAllComponents()
            }
        },
        updateAutocompleteInput (input, init = false) {
            this.dropDownBlocked = true
            this.input = input

            if (typeof this.booknames !== 'undefined' && !init) {
                this.handleAutocompleteInput(input)
            }
            this.lastInput = this.input
        },
        handleDropDownClick (ev, bookname, key) {
            this.input = bookname.input
            this.$bsa && this.$bsa.event({
                eventCategory: 'autocomplete',
                eventAction: `Dropdown ${bookname.type} (click)`,
                eventLabel: this.input,
            })
            this.aktPos = -1
            this.$refs['AUTOCOMPLETE_BTN'].focus()
            this.handleInputAction(null, key, false, true)
        },
        getToBeSearched () {
            let booknames = this.filteredBooknames

            for (let i = 0; i < booknames.length; i++) {
                if (booknames[i].toBeSearched) {
                    return i
                }
            }

            return -1
        },
        syncInput (event) {
            this.input = event.target.value

            // Workaround für Firefox Mobile, weil keyUp nicht triggert
            if (this.isFFandAndroid) {
                this.handleKeyUp(event)
            }
        },
        onPaste (event) {
            this.input = event.target.value
            this.handleKeyUp(event)
        },
        handleInputAction (ev = null, key = -1, search, force = false, clear = false) {
            if(ev) {
                this.$bsa && this.$bsa.event({
                    eventCategory: 'autocomplete',
                    eventAction: `Suchfeld Eintrag ${clear ? 'löschen' : 'ausführen'} (${ev.type})`,
                    eventLabel: this.input,
                })
            }

            if (clear) {
                this.updateAutocompleteInput('')
                this.$refs['AUTOCOMPLETE_BTN'].focus()

                return
            }

            let reg = new RegExp(/"(.*)"/gmi)
            let booknames = this.filteredBooknames
            let countBooknames = this.filteredBooknames.length

            // Klick auf DropDown
            if (key !== -1) {
                if (key === this.filteredBooknames.length - 1) {
                    this.reload = true
                    this.reloadSearch = true
                } else {
                    this.handleAutocompleteInput(this.input)

                    if (force) {
                        // Suche auslösen
                        this.reload = true
                    }
                }
                this.removeFocus()
            } else if (search) {
                // Suche auslösen
                this.reload = true

                if (this.type === 'search') {
                    this.reloadSearch = true
                }
                this.removeFocus()
            } else {
                if (this.aktPos !== -1) {
                    this.reload = true

                    // Wenn letzte Position im Array, dann Suche auslösen, versuchen aufzuschlagen
                    if (this.aktPos === countBooknames - 1 || reg.test(this.input.trim())) {
                        this.reloadSearch = true
                    }
                    this.aktPos = -1
                } else if (countBooknames > 1) {
                    // Versuchen Bibelstelle aufzuschlagen
                    this.reload = true

                    // Input in Anführungszeichen
                    if (reg.test(this.input.trim()) || (this.getToBeSearched() !== -1 && booknames.length - 1 === this.getToBeSearched())) {
                        this.reloadSearch = true
                    }
                } else if (countBooknames === 1) {
                    // Suche auslösen
                    this.reload = true
                    this.reloadSearch = true
                }
                this.removeFocus()
            }
        },
        removeFocus () {
            if (globalThis.isIOS || this.$vuetify.display.smAndDown || globalThis.isAndroid) {
                this.$refs['AUTOCOMPLETE_BTN'].blur()
            }
        },
        getOffset (el) {
            var _x = 0
            var _y = 0
            while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
                _x += el.offsetLeft - el.scrollLeft
                _y += el.offsetTop - el.scrollTop
                el = el.offsetParent
            }

            return {top: _y, left: _x,}
        },
        ...mapActions(useAutocompleteStore, [
            'handleAutocompleteInput',
            'resetToBeSearched',
            'calculateToBeSearched',
            'setComponentVisible',
            'hideAllComponents',
        ]),
        ...mapActions(useBibleStore, [
            'setSelectedBook',
        ]),
        ...mapActions(useRouteMetaStore, [
            'loadRouteMeta',
            'setIsSet',
        ]),
    },
}
</script>

<style lang="scss">
@use "@/assets/js/src/style/loading";

.bs-app .autocomplete-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    max-width: map-deep-get($bs-xl, autocomplete, width);
    margin: 0;
    transition: opacity .15s ease-in-out, max-width .3s ease-out;

    @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
        max-width: map-deep-get($bs-md, autocomplete, width);
    }

    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
        max-width: 100%;
        margin: 0 5px 0 15px;
    }

    .autocomplete-overlay {
        position: fixed;
        top: map-deep-get($bs-xl, header, height);
        left: 0;
        width: 100%;
        height: 200vh;
        height: calc(var(--vh) * 200);
        background: rgb(0 0 0 / 50%);

        @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
            top: map-deep-get($bs-md, header, height);
        }

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            top: map-deep-get($bs-xs, header, height);
        }

        &--banner-visible {
            top: map-deep-get($bs-xl, header, height) + map-deep-get($bs-xl, banner, height);

            @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                top: map-deep-get($bs-md, header, height) + map-deep-get($bs-xl, banner, height);
            }

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                top: map-deep-get($bs-xs, header, height) + map-deep-get($bs-xs, banner, height);
            }
        }
    }

    .bs-slider-color {
        background: map-deep-get($bs-color, bsHighlight);
    }

    .list-enter-from, .list-leave-to {
        max-height: 0;
        transform: scale(1, 0);
        transform-origin: top left;
        opacity: 0;
    }

    .list-enter-active, .list-leave-active {
        transition: .3s cubic-bezier(.25, .8, .5, 1);
    }

    .list-enter-to, .list-leave-from {
        max-height: 40px;
        transform: scale(1, 1);
        transform-origin: top left;
        opacity: 1;
    }

    .autocomplete-input-wrapper {
        position: relative;
        z-index: 5;
        display: flex;
        align-items: center;
        width: 100%;
        height: map-deep-get($bs-xl, autocomplete, height);
        background: map-deep-get($bs-color, white);

        @include dark {
            background: map-deep-get($bs-color, dark, white);
        }

        border-radius: map-deep-get($bs-xl, autocomplete, inputRadius);

        @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
            height: map-deep-get($bs-md, autocomplete, height);
            border-radius: map-deep-get($bs-md, autocomplete, inputRadius);
        }

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            height: 40px;
            border-radius: map-deep-get($bs-xs, autocomplete, inputRadius);
        }

        form {
            width: 100%;
        }

        .autocomplete-input-enter {
            line-height: 1rem;

            &--close {
                width: 18px;
            }
        }

        .autocomplete-btn {
            height: 100%;
            padding: 0 15px;
            color: map-deep-get($bs-color, black);

            @include dark {
                color: map-deep-get($bs-color, dark, black);
            }

            line-height: 1;
            background: transparent;
            border: none;
            transition: .3s cubic-bezier(.25, .8, .5, 1);

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                height: 100%;
                padding: 0 15px;
                color: map-deep-get($bs-color, black);

                @include dark {
                    color: map-deep-get($bs-color, dark, black);
                }

                background: transparent;
                transition: map-deep-get($bs-transition, standard);
            }
        }

        .showBooknamesBtn {
            border-top-left-radius: map-deep-get($bs-xl, autocomplete, inputRadius);
            border-bottom-left-radius: map-deep-get($bs-xl, autocomplete, inputRadius);

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                border-top-left-radius: map-deep-get($bs-xs, autocomplete, inputRadius);
                border-bottom-left-radius: map-deep-get($bs-xs, autocomplete, inputRadius);
            }

            .v-icon {
                width: map-deep-get($bs-xl, autocomplete, btnFontSize);
                height: map-deep-get($bs-xl, autocomplete, btnFontSize);
                color: map-deep-get($bs-color, black);

                @include dark {
                    color: map-deep-get($bs-color, dark, black);
                }

                @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                    width: map-deep-get($bs-md, autocomplete, btnFontSize);
                    height: map-deep-get($bs-md, autocomplete, btnFontSize);
                }

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    width: map-deep-get($bs-xl, autocomplete, btnFontSize);
                    height: map-deep-get($bs-xl, autocomplete, btnFontSize);
                }
            }
        }

        .showBiblesBtn {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            padding-right: 2px;
            font-weight: 400;
            font-size: map-deep-get($bs-xl, autocomplete, fontSize);
            border-top-right-radius: map-deep-get($bs-xl, autocomplete, inputRadius);
            border-bottom-right-radius: map-deep-get($bs-xl, autocomplete, inputRadius);

            @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                font-size: map-deep-get($bs-md, autocomplete, fontSize);
            }

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                padding-right: 2px;
                font-size: 16px;
                border-top-right-radius: map-deep-get($bs-xs, autocomplete, inputRadius);
                border-bottom-right-radius: map-deep-get($bs-xs, autocomplete, inputRadius);
            }

            &.active .v-icon {
                transform: rotate(180deg);
            }
        }

        .autocomplete-btn:focus, .autocomplete-btn:visited {
            outline: none;
            box-shadow: none;
        }

        .autocomplete-btn:hover, .autocomplete-btn.active, .autocomplete-btn:focus {
            color: map-deep-get($bs-color, bsHighlight);
        }

        .autocomplete-btn:hover .v-icon, .autocomplete-btn.active .v-icon, .autocomplete-btn:focus .v-icon {
            color: map-deep-get($bs-color, bsHighlight);
        }

        .autocomplete-input {
            width: 100%;
            height: 100%;
            padding: 0 0 0 2px;
            color: map-deep-get($bs-color, black);

            @include dark {
                color: map-deep-get($bs-color, dark, black);
            }

            font-weight: 400;
            font-size: map-deep-get($bs-xl, autocomplete, fontSize);
            line-height: 25px;
            background: map-deep-get($bs-color, white);

            @include dark {
                background: map-deep-get($bs-color, dark, white);
            }

            cursor: text;

            @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                font-size: map-deep-get($bs-md, autocomplete, fontSize);
            }

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                font-size: 16px;

                &__form {
                    &.autocomplete-firefox {
                        max-width: calc(100% - 120px);

                        &__selected {
                            max-width: calc(100% - 132px);
                        }
                    }
                }
            }

            &-enter-from {
                color: map-deep-get($bs-color, black);

                @include dark {
                    color: map-deep-get($bs-color, dark, black);
                }

                cursor: pointer;
                transition: opacity .2s ease-in-out;

                &:hover, &:focus {
                    svg {
                        color: map-deep-get($bs-color, bsHighlight);
                    }
                }

                &--disabled {
                    opacity: .5;

                    &:hover, &:focus {
                        svg {
                            color: map-deep-get($bs-color, black);

                            @include dark {
                                color: map-deep-get($bs-color, dark, black);
                            }
                        }
                    }
                }

                &--hidden {
                    opacity: 0;
                }
            }
        }
    }

    .autocomplete-input:focus, .autocomplete-input:hover, .autocomplete-input:visited {
        outline: none;
        box-shadow: none;
    }

    // Wrapper für Ansichten

    .autocomplete-elem-wrapper {
        position: absolute;
        top: calc(100% - 3px);
        left: 0;
        z-index: 3;
        display: flex;
        justify-content: flex-start;
        width: 100%;
        height: 80vh;
        height: calc(var(--vh) * 80);
        max-height: map-deep-get($bs-xl, autocomplete, dropdown, height);
        overflow: hidden;
        background: map-deep-get($bs-color, white);
        border: 1px solid map-deep-get($bs-color, greyLight);
        box-shadow: 0 0 3px map-deep-get($bs-color, grey);

        @include dark {
            background: map-deep-get($bs-color, dark, white);
            border: 1px solid map-deep-get($bs-color, dark, greyLight);
            box-shadow: 0 0 3px map-deep-get($bs-color, dark, grey);
        }

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            height: calc((2 / 3) * 100vh);
            height: calc((2 / 3) * var(--vh) * 100);
        }
    }

    .autocomplete-elem-wrapper.autocomplete-select-wrapper {
        height: auto;
        padding-bottom: 44px;

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            height: auto;

            &--banner-visible {
                height: calc((2 / 3) * 100vh - (#{map-deep-get($bs-xs, banner, height)} / 2));
                height: calc((2 / 3) * var(--vh) * 100 - (#{map-deep-get($bs-xs, banner, height)} / 2));

                &.autocomplete-select-wrapper {
                    height: auto;

                    ul {
                        max-height: 132px;
                    }
                }
            }
        }

        ul {
            display: block;
            width: 100%;
            max-height: 396px;
            margin: 0;
            padding: 0;
            overflow: auto;

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                max-height: 176px;
            }

            li {
                display: block;
                width: 100%;
                padding: 0;
                list-style: none;

                .autocomplete-select-item {
                    position: relative;
                    width: 100%;
                    margin: 0;
                    padding: 10px 0 10px 51px;
                    color: map-deep-get($bs-color, autocomplete, font);
                    font-size: map-deep-get($bs-xl, autocomplete, dropdown, fontSize, button);
                    text-align: left;
                    border: none;
                    border-radius: 0;
                    box-shadow: none;
                    transition: map-deep-get($bs-transition, standard);
                }

                .autocomplete-select-item.search span {
                    position: absolute;
                    top: 50%;
                    left: 15px;
                    display: block;
                    transform: translateY(-50%);
                }

                .autocomplete-select-item:hover, .autocomplete-select-item.selected {
                    color: map-deep-get($bs-color, black);

                    @include dark {
                        color: map-deep-get($bs-color, dark, black);
                    }

                    font-weight: bold;
                    background: map-deep-get($bs-color, greyLight);

                    @include dark {
                        background: map-deep-get($bs-color, dark, greyLight);
                    }
                }

                .autocomplete-select-item.to-be-searched {
                    background: map-deep-get($bs-color, greyLight);

                    @include dark {
                        background: map-deep-get($bs-color, dark, greyLight);
                    }
                }

                .autocomplete-select-item:focus, .autocomplete-select-item:visited {
                    outline: none;
                    box-shadow: none;
                }

                &:last-child {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    background: map-deep-get($bs-color, white);

                    @include dark {
                        background: map-deep-get($bs-color, dark, white);
                    }

                    border-top: 1px solid map-deep-get($bs-color, greyLight);

                    @include dark {
                        border-top: 1px solid map-deep-get($bs-color, dark, greyLight);
                    }

                    .autocomplete-select-item span {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .autocomplete-elem-wrapper.text-format-wrapper {
        top: calc(100% + 5px);
        left: 0;
        width: 100%;
        max-width: map-deep-get($bs-xl, header, textFormat, maxWidth);
        height: 80vh;
        height: calc(var(--vh) * 80);
        max-height: map-deep-get($bs-xl, header, textFormat, height);
        overflow: visible;

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            top: calc(100% + 3px);
            right: -50px !important;
            left: auto !important;
            z-index: 5;
            width: map-deep-get($bs-xl, header, textFormat, maxWidth);
            max-width: calc((2 / 3) * 100vw);
            height: map-deep-get($bs-xl, autocomplete, dropdown, height);
            overflow: visible;
        }

        .arrow {
            position: absolute;
            top: -21px;
            left: 0;
            width: 36px;
            height: 36px;
            color: map-deep-get($bs-color, white);

            @include dark {
                color: map-deep-get($bs-color, dark, white);
            }

            transform: translateX(-50%);

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                position: absolute;
                top: -21px;
                right: 0 !important;
                left: auto !important;
                transform: translateX(0);
            }
        }
    }

    //Dropdown Animation

    .dropdown-leave-active {
        box-shadow: none;
        transition: all .3s;

        > div {
            box-shadow: none;
            transition: all .3s;
        }
    }

    .dropdown-enter-active {
        box-shadow: none;
        transition: all .3s cubic-bezier(0, 1.5, .5, 1);

        > div {
            box-shadow: none;
            transition: all .3s cubic-bezier(0, 1.5, .5, 1);
        }
    }

    .autocomplete-select-wrapper.dropdown-enter-active, .autocomplete-select-wrapper.dropdown-leave-active {
        box-shadow: none;
        transition: all .3s cubic-bezier(0, 1, .5, 1);
    }

    .dropdown-enter-from, .dropdown-leave-to {
        max-height: 0;
        overflow: hidden;
        box-shadow: none;
        transform: translateY(-35px); //rotateX(30deg) //scale(0);
        transform-origin: top center;

        > div {
            margin-top: -400px;

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                margin-top: calc((-2 / 3) * 100vh);
                margin-top: calc((-2 / 3) * var(--vh) * 100);
            }
        }
    }

    .dropdown-leave-from, .dropdown-enter-to {
        max-height: 400px;
        box-shadow: none;
        transform: translateY(0) rotateX(0deg); //scale(1)
        transform-origin: top center;

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            max-height: calc((2 / 3) * 100vh);
        }

        > div {
            margin-top: 0;
        }
    }

    .text-format-wrapper.dropdown-leave-active {
        box-shadow: none;
        transition: all .3s cubic-bezier(0, 1, .5, 1);
    }

    .text-format-wrapper.dropdown-enter-from, .text-format-wrapper.dropdown-leave-to {
        padding: 0;
        box-shadow: none;
        transform: scale(0);
    }

    .text-format-wrapper.dropdown-leave-from, .text-format-wrapper.dropdown-enter-to {
        box-shadow: none;
        transform: scale(1);
    }
}
</style>
