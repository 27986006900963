<template xmlns:v-slot="http://www.w3.org/1999/xhtml">
    <v-container
        fluid
        fill-height
        class="text-view-wrapper"
        :class="{'textformat--max-width':textFormats.maxWidth,}"
    >
        <div
            id="chapter-wrapper"
            :class="[{'sidebar-open':isSidebarOpen,'text-view--banner-visible':banner,'safari-or-ios':safariOrIos},'text-view',textFormatsClasses,'base-columns-' + chapters.length]"
            v-bind="showHideData"
            @copy="copyHandler"
        >
            <v-no-ssr>
                <div
                    :key="`mobile-scroll-header-${$vuetify.display.smAndDown}`"
                    class="scroll-header hidden-print-only"
                    :class="{
                        'main-header--pinned':isFixedHeader || mainHeaderClasses['main-header--pinned'],
                        'main-header--top':!headerTop,
                        'main-header--unpinned': mainHeaderClasses['main-header--unpinned'],
                    }"
                >
                    <div
                        :class="['scroll-articles', isSidebarOpen ? 'with-sidebar-open' : 'with-sidebar-closed']"
                    >
                        <div
                            v-for="(abbr, index) in bibleAbbreviations"
                            :key="'scroll_' + abbr"
                            :class="['scroll-bibleheader','col' + chapters.length]"
                            :dir="(allBibles[abbr] || {}).rtl ? DIR_RTL : DIR_LTR"
                            :data-horder="index + 1"
                        >
                            <span
                                v-if="index === 0 && !isFixedHeader"
                                class="text-ref text-truncate"
                            >{{ bookname }} {{ firstChapter.number }}&nbsp;|&nbsp;</span>
                            <span class="text-truncate">{{ getNameByAbbreviation(abbr) }}</span>
                        </div>
                    </div>
                    <div :class="['trans', isSidebarOpen ? 'with-aside-open' : 'with-aside-closed']"></div>
                </div>
            </v-no-ssr>
            <template v-if="messagesLoaded && asyncStatusLoaded">
                <bs-chapter
                    v-for="(chapter, index) in chapters"
                    :key="'chapter_' + chapter.chapter.id + '_' + index"
                    :class="[
                        'fix-order-' + (index + 1),
                        {
                            'hidden-print-only':(textFormats.oneVerseLine || textFormats.printMyNotes || isCompare),'first': index === 0, 'second': index === 1,}]"
                    :data-order="index + 1"
                    :chapter="chapter.chapter"
                    :order="index"
                    :chapter-meta="chaptersData[index]"
                    :bookname="bookname"
                    @select-verse="selectVerse"
                    @select-section="selectSection"
                    @change-order="handleOrder($event)"
                ></bs-chapter>
                <v-no-ssr>
                    <div
                        v-if="moveFootnotes"
                        class="footnotes_ghost"
                    >
                        <print-footnotes
                            :footnote-offsets="chaptersData[0]['verse-footnotes']"
                        ></print-footnotes>
                    </div>
                </v-no-ssr>
            </template>

            <v-no-ssr>
                <print-chapter
                    v-if="!printBtnDisabled && (textFormats.oneVerseLine || textFormats.printMyNotes || isCompare)"
                    :footnote-offsets="chaptersData"
                    :messages-loaded="messagesLoaded"
                    class="hidden-screen-only"
                ></print-chapter>
            </v-no-ssr>

            <v-progress-circular
                v-if="!asyncStatusLoaded || !messagesLoaded"
                class="loading"
                size="100"
                indeterminate
                color="primary"
            ></v-progress-circular>

            <transition
                v-if="componentIsActive"
                name="slide-x-transition"
            >
                <v-btn
                    v-show="(textNav && !sidebarOnMove) || !$vuetify.display.smAndDown"
                    :key="`mobile-text-nav-${$vuetify.display.smAndDown}`"
                    v-scroll="onScroll"
                    :to="{path: prev}"
                    :disabled="(prevUrl || '').length === 0 || !asyncStatusLoaded"
                    icon
                    :title="messagesLoaded ? t('async.text.nav.prev') : ''"
                    :class="['hidden-print-only', 'elevation-0', 'text-nav', 'back', 'arrow-icon--direction', {'with-aside': isSidebarOpen, 'notrans': sidebarOnMove,}]"
                >
                    <v-icon
                        :color="$vuetify.theme.current.dark ? colors.white : colors.black"
                    >
                        bs:$vuetify.icons.mdiArrowLeft
                    </v-icon>
                </v-btn>
            </transition>

            <transition
                v-if="componentIsActive"
                name="slide-x-reverse-transition"
            >
                <v-btn
                    v-show="(textNav && !sidebarOnMove) || !$vuetify.display.smAndDown"
                    :key="`mobile-text-nav-${$vuetify.display.smAndDown}`"
                    :to="{path: next}"
                    :class="['hidden-print-only', 'elevation-0', 'text-nav', 'forward', 'arrow-icon--direction', {'with-aside': isSidebarOpen, 'notrans': sidebarOnMove,}]"
                    :disabled="(nextUrl || '').length === 0 || !asyncStatusLoaded"
                    :title="messagesLoaded ? t('async.text.nav.next') : ''"
                    icon
                >
                    <v-icon
                        :color="$vuetify.theme.current.dark ? colors.white : colors.black"
                    >
                        bs:$vuetify.icons.mdiArrowRight
                    </v-icon>
                </v-btn>
            </transition>
        </div>

        <hydrate
            v-if="isTextViewContext"
            ref="hydrate_sidebar_tv"
            when-idle
        >
            <bs-sidebar
                v-if="($isServer || ($refs['hydrate_sidebar_tv'] || {}).hydrated)"
                :key="`mobile-${$vuetify.display.smAndDown}`"
                :input="sidebarInput"
                v-bind="{messagesLoaded,}"
            ></bs-sidebar>
            <aside v-else></aside>
        </hydrate>

        <v-progress-circular
            id="textViewLoading"
            :class="[{'loading--visible': loadingOpacity > 0.01}, 'hidden-md-and-up', 'hidden-print-only']"
            :model-value="loadingOpacity * 100"
            size="100"
            color="primary"
        ></v-progress-circular>
    </v-container>
</template>

<script>
import Chapter from '@/assets/js/src/modules/text/_components/Chapter.vue'
import Sidebar from '@/assets/js/src/modules/sidebar/_components/Sidebar.vue'
import {useTextStore,} from '@/assets/js/src/modules/text/_pinia/text'
import {getActivePinia, mapActions, storeToRefs,} from 'pinia'
import xs from '@/assets/js/src/style/json/variables-xs'
import Hydrate from '@/assets/js/src/components/LazyHydrate.vue'
import '@/assets/js/src/style/rtl.scss'
import {useAutocompleteStore,} from '@/assets/js/src/modules/autocomplete/_pinia/autocomplete'
import {useRouteMetaStore,} from '@/assets/js/src/pinia/routeMeta'
import {useBibleStore,} from '@/assets/js/src/modules/bible/_pinia/bible'
import {useShowRegisterMessage,} from '@/assets/js/src/modules/snackbar/_composables/useShowRegisterMessage'
import {useSnackbarStore,} from '@/assets/js/src/modules/snackbar/_pinia/snackbar'
import {DIR_LTR, DIR_RTL,} from '@/assets/js/src/modules/lang/_pinia/lang'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {useUserOptions,} from '@/assets/js/src/modules/user/_composables/useUserOptions'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {useTextApi,} from '@/assets/js/src/modules/text/_composables/useTextApi'
import {useComponentIsActive,} from '@/assets/js/src/util/composables/useComponentIsActive'
import {useSpaceScroll,} from '@/assets/js/src/util/composables/useSpaceScroll'
import colors from '@/assets/js/src/style/json/colors'
import {useTouch,} from '@/assets/js/src/util/composables/useTouch'
import {useScrollTo,} from "@/assets/js/src/modules/text/_composables/useScrollTo"
import {Scroll,} from 'vuetify/directives'
import {defineAsyncComponent,} from "vue"
import {useHeadInfo,} from "@/assets/js/src/modules/text/_composables/useHeadInfo"
import {useTextInfo,} from "@/assets/js/src/modules/text/_composables/useTextInfo"
import {useHeadLinkInfo,} from "@/assets/js/src/modules/text/_composables/useHeadLinkInfo"
import {useTextFormats,} from "@/assets/js/src/modules/text/_composables/useTextFormats"
import {useSidebarInfo,} from "@/assets/js/src/modules/text/_composables/useSidebarInfo"
import {useTextSelect,} from "@/assets/js/src/modules/text/_composables/useTextSelect"
import {useTranslation,} from "@/assets/js/src/util/composables/useTranslation"
import {useTopClass,} from "@/assets/js/src/util/composables/useTopClass"

const PAGE_END = 999999

let printComponents = {}
if (!import.meta.env.SSR) {
    printComponents = {
        PrintChapter: defineAsyncComponent(() => import('@/assets/js/src/layout/print/PrintChapter.vue')),
        PrintFootnotes: defineAsyncComponent(() => import('@/assets/js/src/layout/print/PrintFootnotes.vue')),
    }
}

let touchOptions = {
    elemSelector: '#chapter-wrapper',
    loadingElemId: 'textViewLoading',
    goPrev: function () {
        this.goPrev()
        this.$bsa && this.$bsa.event({
            eventCategory: 'touch actions',
            eventAction: 'Vorheriges Kapitel (swipe)',
        })
    },
    goNext: function () {
        this.goNext()
        this.$bsa && this.$bsa.event({
            eventCategory: 'touch actions',
            eventAction: 'Nächstes Kapitel (swipe)',
        })
    },
    hasPrev: function (vm) {
        return (vm.prevUrl || '').length !== 0 && vm.asyncStatusLoaded
    },
    hasNext: function (vm) {
        return (vm.nextUrl || '').length !== 0 && vm.asyncStatusLoaded
    },
    onlyTouch: true,
    onlyMobile: (vm) => {
        return !vm.$vuetify.display.smAndDown
    },
}

export default {
    name: 'TextView',
    components: {
        'bs-sidebar': Sidebar,
        'bs-chapter': Chapter,
        Hydrate,
        ...printComponents,
    },
    directives: {
        Scroll,
    },
    props: {
        isCompare: {
            type: Boolean,
            default: false,
        },
    },
    setup (props) {
        useSpaceScroll()
        useTopClass([ 'with-navigation', 'with-sidebar', ])

        const activePinia = getActivePinia()
        const {
            banner,
            overlay,
            home,
            sidebarOpen,
            printBtnDisabled,
            sidebarOnMove,
            mainHeaderClasses,
            mobileView,
            lastRoute,
            criticalMessagesLoaded,
            menu,
            extraBox,
            savedYPosition,
        } = storeToRefs(useAppUiStore(activePinia))

        const {
            selectedVersesAsArray: selectedVerses,
            chaptersData,
        } = storeToRefs(useTextStore(activePinia))

        const {
            selectedVerses: routeMetaSelectedVerses,
            bibles: routeMetaBibles,
            canonical: routeMetaCanonical,
            type: routeMetaType,
            prevUrl,
            nextUrl,
            chapterCanonical,
        } = storeToRefs(useRouteMetaStore(activePinia))

        const {
            isComponentVisible,
            headingToScroll,
        } = storeToRefs(useAutocompleteStore(activePinia))

        const {
            getNameByAbbreviation,
            selectedBibles,
            bibles: allBibles,
        } = storeToRefs(useBibleStore(activePinia))

        const {
            user,
            loggedIn,
        } = storeToRefs(useUserStore(activePinia))

        const {
            messagesLoaded,
            asyncStatusLoaded,
            componentIsActive,
            updateData,
            chapters,
        } = useTextApi()

        const {
            firstChapter,
            firstBible,
            number,
            bookname,
            chapterIds,
        } = useTextInfo()

        const {
            prev,
            next,
        } = useHeadLinkInfo()

        useHeadInfo()

        return {
            prev,
            next,
            number,
            bookname,
            banner,
            overlay,
            home,
            sidebarOpen,
            printBtnDisabled,
            sidebarOnMove,
            mainHeaderClasses,
            mobileView,
            lastRoute,
            criticalMessagesLoaded,
            menu,
            extraBox,
            savedYPosition,
            selectedVerses,
            chaptersData,
            chapterCanonical,
            isComponentVisible,
            headingToScroll,
            getNameByAbbreviation,
            selectedBibles,
            user,
            loggedIn,
            allBibles,
            routeMetaSelectedVerses,
            routeMetaBibles,
            routeMetaCanonical,
            routeMetaType,
            prevUrl,
            nextUrl,
            messagesLoaded,
            asyncStatusLoaded,
            componentIsActive,
            updateData,
            chapters,
            firstChapter,
            firstBible,
            chapterIds,
            ...useScrollTo(),
            ...useShowRegisterMessage(),
            ...useUserOptions(),
            ...useComponentIsActive(),
            ...useTouch(touchOptions),
            ...useTextFormats(),
            ...useSidebarInfo({isCompare: props.isCompare,}),
            ...useTextSelect(),
            ...useTranslation(),
            DIR_RTL,
            DIR_LTR,
            colors,
        }
    },
    data () {
        return {
            fontFamily: true,
            offset: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, ],
            maxColumnsArr: [],
            verseReferences: [],
            verseFootnotes: [],
            headingSlug: [],
            textNav: true,
            headerTop: false,
            safariOrIos: false,
        }
    },
    computed: {
        bibleAbbreviations: function () {
            return this.chapters.map((chapter) => chapter.chapter.bible.abbreviation)
        },
        isFixedHeader () {
            return this.user.options.fixHeader && !this.mobileView
        },
        isTextViewContext: function () {
            let context = this.$route.meta.uiType === 'TextView'
            if (!context && Object.keys(this.lastRoute ?? {}).length) {
                context = this.lastRoute.route.meta.uiType === 'TextView'
            }

            return context
        },
        moveFootnotes: function () {
            return !this.printBtnDisabled && !this.textFormats.oneVerseLine && !this.textFormats.printMyNotes && !this.isCompare && this.messagesLoaded && this.asyncStatusLoaded
        },
    },
    watch: {
        routeMetaSelectedVerses: {
            handler: function (newValue, oldValue) {
                if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                    // Danach die Verse markieren#
                    let verses = []
                    newValue.forEach((elem) => {
                        for (let i = elem[0]; i <= elem[elem.length - 1]; i++) {
                            verses.push(i)
                        }
                    })

                    this.setSelectedVerses({selectedVerses: [], selected: false,})
                    this.setSelectedVerses({selectedVerses: verses, selected: true,})

                    if (this.asyncStatusLoaded && !import.meta.env.SSR) {
                        setTimeout(() => {
                            if (this.selectedVerses.length !== 0) {
                                let firstSelectedVerse = this.selectedVerses[0][0]

                                let verses = document.querySelectorAll(this.getSelectedVerseCanonical(firstSelectedVerse))

                                if (verses.length > 0) {
                                    this.scrollTo(verses[0])
                                }
                            }
                        })
                    }
                }

            },
            immediate: true,
        },
        chapterCanonical: {
            handler: function (newValue, oldValue) {
                if (newValue !== oldValue && typeof newValue !== 'undefined' && this.loggedIn && this.textFormats.printMyNotes) {
                    this.fetchNotesByChapter({chapterCanonical: newValue,})
                }
            },
            immediate: !import.meta.env.SSR,
        },
        // Hashtag auswerten, öffnen und hinscrollen
        asyncStatusLoaded: {
            handler (newValue, oldValue) {
                if (import.meta.env.SSR) {
                    return
                }
                if (newValue !== oldValue && newValue && this.$route.hash) {
                    setTimeout(() => {
                        let elem = document.getElementById(this.$route.hash.replace('#', ''))

                        if (elem) {
                            this.scrollTo(elem)
                        }
                    })
                } else if (newValue !== oldValue && newValue && this.headingToScroll.trim() !== '') {
                    setTimeout(() => {
                        let heading = document.getElementById(this.headingToScroll)

                        if (heading) {
                            this.scrollTo(heading)
                        }

                        this.headingToScroll = ''
                    })
                } else if (newValue !== oldValue && newValue) {
                    setTimeout(() => {
                        if (this.selectedVerses.length !== 0) {
                            let firstSelectedVerse = this.selectedVerses[0][0]

                            let verses = document.querySelectorAll(this.getSelectedVerseCanonical(firstSelectedVerse))

                            if (verses.length > 0) {
                                this.scrollTo(verses[0])
                            }
                        }
                    })
                }

                if (newValue !== oldValue && newValue && this.isCompare && this.chaptersData[0]['rows-diff']) {
                    this.showMessage({
                        message: this.t('async.autocomplete.compare_diff_notice'),
                        type: 'info',
                        html: true,
                    })
                }
            },
            immediate: !import.meta.env.SSR,
        },
        $route (to, from) {
            if (to.meta.uiType !== 'TextView') {
                return
            }

            this.updateData(true).then((data) => {
                if (to.meta.uiType !== from.meta.uiType && data[0]?.reloaded !== false) {
                    // Danach die Verse markieren
                    let verses = []
                    this.routeMetaSelectedVerses.forEach((elem) => {
                        for (let i = elem[0]; i <= elem[elem.length - 1]; i++) {
                            verses.push(i)
                        }
                    })

                    this.setSelectedVerses({selectedVerses: [], selected: false,})
                    this.setSelectedVerses({selectedVerses: verses, selected: true,})
                }

                if (this.savedYPosition === 0) {
                    return
                }

                setTimeout(() => {
                    if (this.savedYPosition === PAGE_END) {
                        window.scrollTo(0, document.body.scrollHeight)
                    } else {
                        window.scrollTo(0, this.savedYPosition)
                    }
                    this.savedYPosition = 0
                }, 300)
            })

            if (from.meta.uiType !== 'TextView' || import.meta.env.SSR || !this.componentIsActive) {
                return
            }

            // Verse abwählen
            if (this.routeMetaSelectedVerses.length === 0) {
                this.setSelectedVerses({selectedVerses: [], selected: false,})
            }

            // Nach oben scrollen die Seitenleiste
            try {
                let sidebar = document.querySelector('#sidebar .aside-info-content')
                if (sidebar !== null && !this.extraBox.show) {
                    sidebar.scrollTop = 0
                }
            } catch { /* empty */ }

            // Nach oben scrollen den main window
            window.scrollTo(0, 0)
        },
        routeMetaCanonical: function (newVal) {
            if (this.routeMetaType === 'text' && newVal !== decodeURIComponent(this.$route.path) && this.componentIsActive) {
                history.replaceState(history.state, document.title, newVal)
            }
        },
        criticalMessagesLoaded: {
            handler: function (newValue, oldValue) {
                if (newValue !== oldValue && newValue) {
                    if (this.isCompare && this.$vuetify.display.width <= xs.breakpointValueHalf && this.$vuetify.display.smAndDown) {
                        this.showMessage({
                            message: this.t('async.autocomplete.compare_mobile_notice'),
                            type: 'warning',
                        })
                    }
                }
            },
            immediate: !import.meta.env.SSR,
        },
        moveFootnotes: function (newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    let times = 0
                    let footnotes = document.querySelector('.footnotes_ghost .print-footer')

                    const interval = setInterval(() => {
                        times += 1
                        if (footnotes || times === 6) {
                            clearInterval(interval)
                            // Verschiebe Footnotes in der Druckansicht über den Footer (nur bei Fließtext)
                            let footer = document.querySelector('.text-view footer')
                            let movedFootnotes = document.querySelector('.chapter .print-footer')
                            !movedFootnotes && footnotes && footer && footer.parentNode.insertBefore(footnotes, footer)
                        }
                        try {
                            footnotes = document.querySelector('.footnotes_ghost .print-footer')
                            // eslint-disable-next-line no-unused-vars
                        } catch (e) {
                            clearInterval(interval)
                        }
                    }, 1000)
                })
            }
        },
    },
    created () {
        this.oldScrollPos = 0
    },
    mounted () {
        if (!this.$vuetify.display.smAndDown) {
            document.addEventListener('keyup', this.handleKeyUp)
        }

        this.safariOrIos = globalThis.isSafari || globalThis.isIOS
    },
    beforeUnmount () {
        document.removeEventListener('keyup', this.handleKeyUp)
    },
    methods: {
        ...mapActions(useBibleStore, [
            'setSelectedBibles',
        ]),
        ...mapActions(useSnackbarStore, [
            'showMessage',
        ]),
        ...mapActions(useTextStore, [
            'setSelectedVerses',
            'setSelectedVerse',
            'fetchNotesByChapter',
        ]),
        goPrev () {
            if ((this.prevUrl || '').length === 0 || !this.asyncStatusLoaded) {
                return
            }
            this.$router.push({path: this.prev,}).catch(() => {
            })
        },
        goNext () {
            if ((this.nextUrl || '').length === 0 || !this.asyncStatusLoaded) {
                return
            }
            this.$router.push({path: this.next,}).catch(() => {
            })
        },
        getSelectedVerseCanonical (verse) {
            return this.firstChapter.canonical ? '.v' + (this.firstChapter.canonical + verse) : undefined
        },
        handleKeyUp (event) {
            if (this.componentIsActive && !this.overlay && !this.menu && !this.isComponentVisible && event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA' && !event.target.classList.contains('ck-content')) {
                /** ArrowLeft **/
                if (([ 'ArrowLeft', 'Left', ].includes(event.key) && !event.metaKey && !event.shiftKey) || event.keyCode === 74) {
                    let ctrl = globalThis.isMac ? event.altKey : event.ctrlKey
                    if (ctrl) {
                        this.savedYPosition = PAGE_END
                        this.goPrev()
                        this.$bsa && this.$bsa.event({
                            eventCategory: 'shortcuts',
                            eventAction: `${event.keyCode === 74 ? 'j' : '<-'} (vorheriges Kapitel – Seitenende)`,
                        })
                    } else {
                        this.goPrev()
                        this.$bsa && this.$bsa.event({
                            eventCategory: 'shortcuts',
                            eventAction: `${event.keyCode === 74 ? 'j' : '<-'} (vorheriges Kapitel)`,
                        })
                    }
                    /** ArrowRight **/
                } else if (([ 'ArrowRight', 'Right', ].includes(event.key) && !event.metaKey && !event.shiftKey) || event.keyCode === 75) {
                    this.goNext()
                    this.$bsa && this.$bsa.event({
                        eventCategory: 'shortcuts',
                        eventAction: `${event.keyCode === 74 ? 'k' : '->'} (nächstes Kapitel)`,
                    })
                }
            }
        },
        onScroll (e) {
            if (!import.meta.env.SSR) {
                let scrollPos = window.pageYOffset || e.target.scrollTop || 0
                if (this.oldScrollPos > scrollPos) {
                    this.textNav = true
                } else {
                    this.textNav = false
                }
                let trigger = 20
                if (this.$vuetify.display.smAndDown) {
                    trigger = 10
                } else if (this.$vuetify.display.mdOnly) {
                    trigger = 35
                } else if (this.$vuetify.display.lgAndUp) {
                    trigger = 65
                }
                this.headerTop = scrollPos > trigger
                if ((Math.max(document.documentElement.offsetHeight, document.body.offsetHeight) - document.documentElement.clientHeight - window.pageYOffset) < 30) {
                    this.textNav = true
                }
                this.oldScrollPos = scrollPos
            }
        },
        handleOrder (chapterId) {
            if (!this.loggedIn) {
                this.showRegisterMessage({
                    messageKey: 'async.text.swap.login_notice',
                })

                return
            }
            this.$bsa && this.$bsa.event({
                eventCategory: 'button actions',
                eventAction: 'Vertausche Bibelübersetzungen in Textansicht',
            })
            let preIndex = this.chapterIds.indexOf(chapterId)
            let swapOrder = (attr) => {
                let swapClass1 = preIndex + 1
                let swapSelector1 = `[data-${attr}="${swapClass1}"]`
                let swapElem1 = this.$el.querySelector(swapSelector1)
                let swapClass2 = preIndex + 2
                let swapSelector2 = `[data-${attr}="${swapClass2}"]`
                let swapElem2 = this.$el.querySelector(swapSelector2)
                if (swapElem1 && swapElem2) {
                    swapElem1.dataset[attr] = swapClass2
                    swapElem2.dataset[attr] = swapClass1
                    if (this.$vuetify.display.smAndDown && this.isCompare) {
                        if (swapElem1.classList.contains('first')) {
                            swapElem1.classList.remove('first')
                            swapElem2.classList.remove('second')
                            swapElem1.classList.add('second')
                            swapElem2.classList.add('first')
                        } else {
                            swapElem1.classList.remove('second')
                            swapElem2.classList.remove('first')
                            swapElem1.classList.add('first')
                            swapElem2.classList.add('second')
                        }
                    }
                }
            }
            swapOrder('order')
            swapOrder('horder')

            let bibleAbbreviations = globalThis.clone(this.selectedBibles)

            if (preIndex + 1 <= bibleAbbreviations.length - 1) {
                bibleAbbreviations.swap(preIndex, preIndex + 1)
                this.setSelectedBibles(bibleAbbreviations)
                // To replace current URL without adding it to history entries, use replaceState:
                window.history.replaceState({}, document.title, '/' + bibleAbbreviations.join('.') + '/' + this.$route.params.textref)
            }
        },
    },
}
</script>

<style lang="scss">
@use "@/assets/js/src/style/scrollheader";
@use "@/assets/js/src/style/textformat";

.bs-app {
    .text-view {
        .text-nav {
            &.back, &.forward {
                --sum-bottom-offset: max(var(--cch), calc(var(--audio-player-offset) + var(--sidebar-mobile-offset)));
                --position-margin: #{map-deep-get($bs-xl, text,textNav,padding)}px;

                position: fixed;
                right: auto;
                bottom: calc(var(--position-margin) + var(--sum-bottom-offset));
                width: 36px;
                height: 36px;
                color: map-deep-get($bs-color, black);
                background-color: map-deep-get($bs-color, grey);

                @include dark {
                    color: map-deep-get($bs-color, dark, black);
                    background-color: map-deep-get($bs-color, dark, white);
                }

                &.v-btn--disabled::before {
                    background-color: map-deep-get($bs-color, greyLight);
                    opacity: 1;

                    @include dark {
                        background-color: map-deep-get($bs-color, dark, greyLight);
                    }
                }
            }

            &.back {
                left: var(--position-margin);

                &--pinned {
                    transition: .5s;
                }

                &--unpinned {
                    transform: translateX(-100px);
                    transition: .5s;
                }
            }

            &.forward {
                right: calc(var(--position-margin) + var(--bs-sidebar-width));

                &--pinned {
                    transition: .5s;
                }

                &--unpinned {
                    transform: translateX(+100px);
                    transition: .5s;
                }
            }

            .v-icon {
                width: 22px;
                height: 22px;
            }
        }

        &-wrapper {
            #textViewLoading {
                position: fixed;
                top: 50vh;
                left: 50%;
                z-index: -1;
                transform: translate(-50%, -50%);

                &.loading--visible {
                    z-index: 1;
                }

                .v-progress-circular__overlay {
                    transition: none;
                }
            }
        }
    }
}
</style>
