import fetch from '@/assets/js/src/util/fetch'
import {checkResponse, handleException, paramsToQuery,} from '@/assets/js/src/util/apiTools'
import {safeDecodeURI,} from '@/assets/js/src/util/safeDecodeURI'
import cookieManager from '@/assets/js/src/util/cookieManagerWrapper'
import {safeEncodeURI,} from '@/assets/js/src/util/safeEncodeURI'
import {getDefaultHeaders,} from '@/assets/js/src/util/fetch/defaultHeaders'
import {useAutocompleteStore,} from '@/assets/js/src/modules/autocomplete/_pinia/autocomplete'
import {defineStore, getActivePinia,} from 'pinia'
import {useRouteMetaStore,} from '@/assets/js/src/pinia/routeMeta'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {useBibleStore,} from "@/assets/js/src/modules/bible/_pinia/bible.js"

const API_PREFIX_SEARCH = '/api/search'

const defaultsWithoutRelevance = {
    area: [],
    fullwords: true,
    umlaute: false,
    exactPhrase: false,
    searchHeadings: false,
}

const defaults = {
    ...defaultsWithoutRelevance,
    sortByRelevance: false,
}

const defaultsHash = JSON.stringify(defaultsWithoutRelevance)


export const useSearchStore = defineStore('search', {
    state: () => ({
        searchResult: {},
        ...defaults,
        page: 1,
        exceeded: false,
        canonical: 0,
        lastCanonical: 0,
        offsets: {},
        active: false,
    }),
    getters: {
        areaForApi: (state) => {
            let set = new Set(state.area)
            let atNumbers = state.area.filter(booknumber => parseInt(booknumber) >= 1 && parseInt(booknumber) <= 39)
            if (atNumbers.length === 39) {
                atNumbers.forEach(booknumber => set.delete(booknumber))
                set.add('at')
            }
            let ntNumbers = state.area.filter(booknumber => parseInt(booknumber) >= 40 && parseInt(booknumber) <= 66)
            if (ntNumbers.length === 27) {
                ntNumbers.forEach(booknumber => set.delete(booknumber))
                set.add('nt')
            }
            let akNumbers = state.area.filter(booknumber => parseInt(booknumber) >= 90 && parseInt(booknumber) <= 96)
            if (akNumbers.length === 7) {
                akNumbers.forEach(booknumber => set.delete(booknumber))
                set.add('ak')
            }

            return Array.from(set)
        },
        maxPages: (state) => state.searchResult.max_pages,
        optionsHash: (state) => {
            let options = {
                area: state.area,
                fullwords: state.fullwords,
                umlaute: state.umlaute,
                exactPhrase: state.exactPhrase,
                searchHeadings: state.searchHeadings,
            }

            return JSON.stringify(options)
        },
        options: (state) => {
            return {
                area: state.area,
                fullwords: state.fullwords,
                exactPhrase: state.exactPhrase,
                searchHeadings: state.searchHeadings,
                umlaute: state.umlaute,
            }
        },
        isChanged () { // todo test
            return defaultsHash !== this.optionsHash
        },
        optionsAsJsonString: (state) => {
            let filter = JSON.stringify({
                area: state.area,
                fullwords: state.fullwords,
                umlaute: state.umlaute,
                exactPhrase: state.exactPhrase,
                searchHeadings: state.searchHeadings,
                sortByRelevance: state.sortByRelevance,
            })
            if (filter !== JSON.stringify(defaults)) {
                return JSON.stringify({filter,})
            } else {
                return null
            }

        },
        optionsAsParams () {
            return paramsToQuery(JSON.parse(this.optionsAsJsonString || '{}'))
        },
    },
    actions: {
        appendSearchResult (result) {
            Object.keys(result).forEach((abbreviation) => {
                Object.keys(result[abbreviation].marks).forEach((verseId) => {
                    this.searchResult.result[abbreviation].marks[verseId] = result[abbreviation].marks[verseId]
                })
                this.searchResult.result[abbreviation].verses.push(...result[abbreviation].verses)
                Object.keys(result[abbreviation].booknames).forEach((bookNumber) => {
                    if (typeof this.searchResult.result[abbreviation].booknames[bookNumber] === 'undefined') {
                        this.searchResult.result[abbreviation].booknames[bookNumber] = result[abbreviation].booknames[bookNumber]
                    }
                })
            })
        },
        setOptions (options) {
            this.fullwords = options.fullwords
            this.exactPhrase = options.exactPhrase
            this.searchHeadings = options.searchHeadings
            this.area = options.area
            this.umlaute = options.umlaute
        },
        setOptionsFromParams (params) {
            if (!params.filter) {
                return
            }
            params = JSON.parse(params.filter)
            this.fullwords = typeof params.fullwords !== 'undefined' ? params.fullwords : this.fullwords
            this.exactPhrase = typeof params.exactPhrase !== 'undefined' ? params.exactPhrase : this.exactPhrase
            this.umlaute = typeof params.umlaute !== 'undefined' ? params.umlaute : this.umlaute
            this.searchHeadings = typeof params.searchHeadings !== 'undefined' ? params.searchHeadings : this.searchHeadings
            this.sortByRelevance = typeof params.sortByRelevance !== 'undefined' ? params.sortByRelevance : this.sortByRelevance
            this.area = typeof params.area !== 'undefined' ? params.area : this.area
        },
        resetOptions () {
            this.area = defaults.area
            this.fullwords = defaults.fullwords
            this.umlaute = defaults.umlaute
            this.exactPhrase = defaults.exactPhrase
            this.searchHeadings = defaults.searchHeadings
            this.offsets = {}
        },

        async fetchSearch ({canonical, page, route, searchTerm,}) {
            try {
                let activePinia = getActivePinia()

                this.active = true
                let params = route.params
                let abbr = ((typeof params.abbreviation !== 'undefined') ? params.abbreviation : '') + ((typeof params.rest !== 'undefined') ? '.' + params.rest : '')

                if(!abbr) {
                    let bibleStore = useBibleStore(activePinia)
                    abbr = encodeURIComponent(bibleStore.selectedBibles.join('.'))
                }

                let search = searchTerm || params.slug || ''
                Array.isArray(search) && (search = search.join())

                if (!search.length) {
                    return
                }
                
                let routeMetaStore = useRouteMetaStore(activePinia)

                let newRoute = `/search/${safeEncodeURI(abbr)}/${safeEncodeURI(search)}`

                // Parameter aus routeMeta übernehmen
                if (Object.keys(routeMetaStore.params).length !== 0) {
                    this.setOptionsFromParams(routeMetaStore.params)
                    routeMetaStore.deleteParams()
                }

                if (canonical === 0) {
                    this.exceeded = false
                }

                // Exakte Phrase prüfen
                if (this.exactPhrase) {
                    search = `"${search.replace(/^"|"$/gm, '')}"`
                }

                // Sortierung anpassen je nach Kontext
                if (params.rest || !this.fullwords) {
                    this.sortByRelevance = false
                }
                
                if (!import.meta.env.SSR) {
                    let appUiStore = useAppUiStore(activePinia)
                    if (appUiStore.mobileView && !appUiStore.mobileViewLandscape) {
                        abbr = abbr.split('.').slice(0, 1).join('.')
                    } else if (appUiStore.mobileView && appUiStore.mobileViewLandscape) {
                        abbr = abbr.split('.').slice(0, 2).join('.')
                    }
                }

                let rootState = activePinia.state.value

                // Api-Request senden
                let apiResponse = await fetch({
                    url: API_PREFIX_SEARCH,
                    options: {
                        method: 'POST',
                        body: JSON.stringify({
                            search,
                            abbr,
                            area: this.areaForApi,
                            fullwords: this.fullwords,
                            umlaute: this.umlaute,
                            searchHeadings: this.searchHeadings,
                            sortByRelevance: this.sortByRelevance,
                            offsets: page === 1 ? {} : this.offsets,
                            canonical,
                            page,
                        }),
                        headers: getDefaultHeaders({}),
                    },
                })

                // Die search-Options im Cookie setzen
                if (this.optionsAsJsonString) {
                    cookieManager.set('bs-params', this.optionsAsJsonString, {
                        secure: true,
                    })
                } else if (cookieManager.get('bs-params')) {
                    cookieManager.remove('bs-params')
                }

                // Prüfen der Api Antwort
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                if (this.sortByRelevance) {
                    this.lastCanonical = 0
                    this.page = apiResponse.data.page + 1

                    let count = Object.values(apiResponse.data.result).reduce((carry, value) => carry + parseInt(value.count), 0)

                    if (page === 1) {
                        this.searchResult = apiResponse.data
                    } else if (count > 0) {
                        this.appendSearchResult(apiResponse.data.result)
                    } else {
                        this.exceeded = true
                    }
                } else {
                    this.lastCanonical = apiResponse.data.last_canonical
                    this.page = 1

                    let count = Object.values(apiResponse.data.result).reduce((carry, value) => carry + parseInt(value.count), 0)

                    if (canonical === 0) {
                        this.searchResult = apiResponse.data
                    } else if (count > 0) {
                        this.appendSearchResult(apiResponse.data.result)
                    } else {
                        this.exceeded = true
                    }
                }

                this.offsets = apiResponse.data.offsets
                this.canonical = canonical
                
                // Such-Options einlenden
                let autocompleteStore = useAutocompleteStore(getActivePinia())
                autocompleteStore.isSearch = true

                // Auch RouteMeta laden
                if (!import.meta.env.SSR && (safeDecodeURI(newRoute) !== rootState.routeMeta.canonical)) {
                    routeMetaStore.loadRouteMeta({
                        url: newRoute.slice(1),
                        type: 'search',
                        reload: false,
                    })
                }

            } catch (e) {
                handleException(e, false, false)
            } finally {
                this.active = false
            }
        },
    },
})
